import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonGroup } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  onChangeSaveModalPreference,
  onChangeTabValues,
  fetchStatus,
} from "../../store/slices/preferenceSlice";
import { checkIsMappingsSaved } from "../../store/slices/mappingSlice";

import { onSaveContactSyncRules } from "../contacts/SyncRules";
import {
  onSaveInvoiceSyncRules,
  checkCountForProductMapped,
} from "../invoices/SyncRules";
import { onSaveDefaultMappings } from "../contacts/DefaultMappings";
import { onSaveMappings } from "../mapping/MappingWindow";
import { onSaveProductSyncRules } from "../products/SyncRules";
import { onSaveJournalMappings } from "../journalEntries/JournalEntries";

import { useCmsHook } from "../../helpers/cmsHook";

const AutoSaveModal = () => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  // preference states
  const {
    saveModalPreference,
    settingsActiveTab,
    contactsActiveTab,
    invoiceActiveTab,
    productActiveTab,
  } = useSelector((state) => state.preference);

  // contact states
  const {
    isContactsSyncRulesSaved,
    isContactDefaultMappingsSaved,
    economicFilterPreference,
    shopifyFilterPreference,
    shopifyCustomerNumberPreference,
    shopifyCustomerPreference,
    paymentTermPreference,
    currencyPreference,
    layoutPreference,
    customerGroupPreference,
    vatZonePreference,
    ecoDefaultFields: {
      ecoDefaultFields: { paymentTerms, customerGroups },
    },
  } = useSelector((state) => state.contact);

  // invoice states
  const {
    isInvoiceSyncRulesSaved,
    preferenceForStages,
    preferenceForInvoice,
    orderDiscountPreference,
    shippingDischargePreference,
    discount: { orderDiscount, shippingDischarge },
  } = useSelector((state) => state.invoice);

  // product states
  const {
    preferenceForProducts,
    defaultProductPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
    preferenceForSearchParameters,
    shopifyProductFilterPreference,
    economicProductFilterPreference,
    isProductSyncRulesSaved,
  } = useSelector((state) => state.product);

  // journal entries states
  const {
    journalAccountPreference,
    accountsShopifyPaymentPreference,
    accountsPaymentBankPreference,
    shopifyPaymentsText,
    paymentBankText,
    customerPaymentText,
    isJournalEntriesSaved,
    createJournalEntries,
  } = useSelector((state) => state.journalEntries);

  // mapping states
  const {
    isMappingsSaved,
    mappings: { defaultMappings },
  } = useSelector((state) => state.mapping);

  const onSaveUnsavedFields = async () => {
    if (settingsActiveTab === "contact") {
      if (contactsActiveTab === "syncRules" && !isContactsSyncRulesSaved) {
        await onSaveContactSyncRules({
          setIsLoading,
          searchParams,
          economicFilterPreference,
          shopifyFilterPreference,
          shopifyCustomerNumberPreference,
          shopifyCustomerPreference,
          dispatch,
          cmsInstance,
        });
      } else if (
        contactsActiveTab === "defaultMappings" &&
        !isContactDefaultMappingsSaved
      ) {
        await onSaveDefaultMappings({
          setIsLoading,
          searchParams,
          paymentTermPreference,
          currencyPreference,
          layoutPreference,
          customerGroupPreference,
          vatZonePreference,
          dispatch,
          cmsInstance,
        });
      } else if (
        contactsActiveTab === "fieldMappings" &&
        !isMappingsSaved.customer
      ) {
        await onSaveMappings({
          setIsLoading,
          searchParams,
          mappings: defaultMappings.customer,
          mappingsFor: "customer",
          dispatch,
          cmsInstance,
          notificationPath: "contactFieldMappings",
        });
      }
    } else if (settingsActiveTab === "invoice") {
      if (invoiceActiveTab === "syncRules" && !isInvoiceSyncRulesSaved) {
        await onSaveInvoiceSyncRules({
          setIsLoading,
          searchParams,
          dispatch,
          cmsInstance,
          preferenceForStages,
          preferenceForInvoice,
          orderDiscount,
          shippingDischarge,
          orderDiscountPreference,
          shippingDischargePreference,
        });
      } else if (
        invoiceActiveTab === "fieldMappings" &&
        !isMappingsSaved.deal
      ) {
        await onSaveMappings({
          setIsLoading,
          searchParams,
          mappings: defaultMappings.deal,
          mappingsFor: "deal",
          dispatch,
          cmsInstance,
          notificationPath: "invoiceFieldMappings",
        });
      }
    } else if (settingsActiveTab === "product") {
      if (productActiveTab === "syncRules" && !isProductSyncRulesSaved) {
        await onSaveProductSyncRules({
          setIsLoading,
          searchParams,
          dispatch,
          preferenceForProducts,
          defaultProductPreference,
          preferenceForCreatingProduct,
          defaultProductForCreating,
          preferenceForSearchParameters,
          shopifyProductFilterPreference,
          economicProductFilterPreference,
          cmsInstance,
        });
        if (
          !isMappingsSaved.products &&
          preferenceForSearchParameters === "setCustomMappings" &&
          checkCountForProductMapped({ mappings: defaultMappings.products }) > 0
        ) {
          await onSaveMappings({
            dispatch,
            setIsLoading,
            searchParams,
            mappings: defaultMappings.products,
            mappingsFor: "products",
            notificationPath: "productMappings",
            cmsInstance,
          });
        }
      } else if (
        productActiveTab === "fieldMappings" &&
        !isMappingsSaved.productFields
      ) {
        await onSaveMappings({
          mappings: defaultMappings.productFields,
          setIsLoading,
          searchParams,
          dispatch,
          mappingsFor: "productFields",
          notificationPath: "productFieldMappings",
          cmsInstance,
        });
      }
    } else if (settingsActiveTab === "journals") {
      if (!isJournalEntriesSaved) {
        await onSaveJournalMappings({
          searchParams,
          dispatch,
          setIsLoading,
          journalAccountPreference,
          accountsShopifyPaymentPreference,
          accountsPaymentBankPreference,
          shopifyPaymentsText,
          paymentBankText,
          customerPaymentText,
          createJournalEntries,
          cmsInstance,
        });
      }
    }
    dispatch(onChangeSaveModalPreference({ show: false }));
    dispatch(onChangeTabValues());
  };

  const onDiscardChanges = () => {
    const shop = searchParams.get("shop");

    dispatch(fetchStatus({ shop }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "customer" }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "deal" }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "products" }));
    dispatch(checkIsMappingsSaved({ mappingsFor: "productFields" }));
    dispatch(onChangeSaveModalPreference({ show: false }));
    dispatch(onChangeTabValues());
  };

  const renderButtons = () => {
    if (
      settingsActiveTab === "invoice" &&
      invoiceActiveTab === "syncRules" &&
      ((orderDiscount && !orderDiscountPreference) ||
        (shippingDischarge && !shippingDischargePreference))
    ) {
      return (
        <Button
          className="auto-save-btn"
          primary
          onClick={() => {
            dispatch(onChangeSaveModalPreference({ show: false }));
            window.scrollTo(0, 100);
          }}
        >
          Edit
        </Button>
      );
    } else if (
      (paymentTerms && paymentTerms.length <= 0) ||
      (customerGroups && customerGroups.length <= 0)
    ) {
      return (
        <Button
          className="auto-save-btn"
          primary
          onClick={() => dispatch(onChangeSaveModalPreference({ show: false }))}
        >
          Cancel
        </Button>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      preferenceForSearchParameters === "setCustomMappings" &&
      checkCountForProductMapped({ mappings: defaultMappings.products }) === 0
    ) {
      return (
        <Button
          className="auto-save-btn"
          primary
          onClick={() => {
            dispatch(onChangeSaveModalPreference({ show: false }));
            window.scrollTo(0, 100);
          }}
        >
          Edit
        </Button>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      ((preferenceForProducts === "useDefaultProduct" &&
        !defaultProductPreference) ||
        (preferenceForProducts === "findCreateProduct" &&
          preferenceForCreatingProduct &&
          !defaultProductForCreating))
    ) {
      return (
        <Button
          className="auto-save-btn"
          primary
          onClick={() => {
            window.scrollTo(0, document.body.scrollHeight);
            dispatch(onChangeSaveModalPreference({ show: false }));
          }}
        >
          Edit
        </Button>
      );
    } else if (
      settingsActiveTab === "journals" &&
      createJournalEntries &&
      (!journalAccountPreference ||
        !accountsShopifyPaymentPreference ||
        !accountsPaymentBankPreference)
    ) {
      return (
        <Button
          className="auto-save-btn"
          primary
          onClick={() => {
            window.scrollTo(0, document.body.scrollHeight);
            dispatch(onChangeSaveModalPreference({ show: false }));
          }}
        >
          Edit
        </Button>
      );
    } else {
      return (
        <ButtonGroup>
          <Button
            className="discard-button me-3"
            onClick={() => onDiscardChanges()}
            destructive
          >
            Discard
          </Button>
          <Button
            className="auto-save-btn"
            onClick={() => onSaveUnsavedFields()}
            disabled={isLoading}
            primary
            loading={isLoading}
          >
            Save changes
          </Button>
        </ButtonGroup>
      );
    }
  };

  const onRenderContent = () => {
    if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      preferenceForSearchParameters === "setCustomMappings" &&
      checkCountForProductMapped({ mappings: defaultMappings.products }) === 0
    ) {
      return (
        <div className="auto-save-modal">
          <p>
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.productMappings.message}
          </p>
        </div>
      );
    } else if (
      settingsActiveTab === "product" &&
      productActiveTab === "syncRules" &&
      ((preferenceForProducts === "useDefaultProduct" &&
        !defaultProductPreference) ||
        (preferenceForProducts === "findCreateProduct" &&
          preferenceForCreatingProduct &&
          !defaultProductPreference))
    ) {
      return (
        <div className="auto-save-modal">
          <p>
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.selectProduct.message}
          </p>
        </div>
      );
    } else if (
      settingsActiveTab === "invoice" &&
      invoiceActiveTab === "syncRules" &&
      ((orderDiscount && !orderDiscountPreference) ||
        (shippingDischarge && !shippingDischargePreference))
    ) {
      return (
        <div className="auto-save-modal">
          <p>
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.orderDiscountAndShippingCharge.message}
          </p>
        </div>
      );
    } else if (
      (paymentTerms && paymentTerms.length <= 0) ||
      (customerGroups && customerGroups.length <= 0)
    ) {
      return (
        <div className="auto-save-modal">
          <p>
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.defaultSettings.message}
          </p>
        </div>
      );
    } else if (
      settingsActiveTab === "journals" &&
      createJournalEntries &&
      (!journalAccountPreference ||
        !accountsShopifyPaymentPreference ||
        !accountsPaymentBankPreference)
    ) {
      return (
        <div className="auto-save-modal">
          <p>Please select the required fields.</p>
        </div>
      );
    } else {
      return (
        <div className="auto-save-modal">
          <p className="rules-description">
            {cmsInstance &&
              cmsInstance.sendData().cmsContent.notifications.autoSave
                .successContent.unsavedChanges.message}
          </p>
        </div>
      );
    }
  };
  return (
    <Modal
      show={saveModalPreference}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>{onRenderContent()}</Modal.Body>
      <Modal.Footer>{renderButtons()}</Modal.Footer>
    </Modal>
  );
};

export default AutoSaveModal;
