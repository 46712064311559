import { createSlice } from "@reduxjs/toolkit";
import { fetchStatus } from "./preferenceSlice";

import {
  onDeleteExistingMapping,
  onSaveEditedMappings,
  onAddShFieldsToCustomMappings,
} from "./mappingSlice";

const initialState = {
  isProductSyncRulesSaved: false,
  economicProductFilterPreference: undefined,
  shopifyProductFilterPreference: undefined,
  preferenceForSearchParameters: "selectSearchParameters",
  preferenceForProducts: "doNotGenerateInvoice",
  defaultProductPreference: undefined,
  preferenceForCreatingProduct: false,
  defaultProductForCreating: undefined,
  productGroupPreference: undefined,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    onChangePreferenceForSearchParameters(state, { payload }) {
      state.preferenceForSearchParameters = payload.selected;
      state.isProductSyncRulesSaved = false;
    },

    onChangePreferenceForProducts(state, { payload }) {
      state.preferenceForProducts = payload.selected;
      state.isProductSyncRulesSaved = false;
      if (state.preferenceForCreatingProduct) {
        if (
          payload.selected === "doNotGenerateInvoice" ||
          payload.selected === "useDefaultProduct"
        ) {
          state.preferenceForCreatingProduct = false;
        }
      }
    },

    onChangePreferenceForCreatingProduct(state) {
      state.preferenceForCreatingProduct = !state.preferenceForCreatingProduct;
      if (state.preferenceForCreatingProduct === false) {
        state.defaultProductForCreating = undefined;
      }
      state.isProductSyncRulesSaved = false;
    },

    onChangeProductPreference(state, { payload }) {
      if (payload.selectingFor === "shopifyProductFilterDropdown") {
        state.shopifyProductFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "economicProductFilterDropdown") {
        state.economicProductFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "product") {
        state.defaultProductForCreating = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "defaultProduct") {
        state.defaultProductPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
      } else if (payload.selectingFor === "productGroup") {
        state.productGroupPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
      }
      state.isProductSyncRulesSaved = false;
    },

    setIsProductSyncRulesSaved(state) {
      state.isProductSyncRulesSaved = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStatus.fulfilled, (state, actions) => {
      const { preference, mapping } = actions.payload;

      if (preference && Object.keys(preference).length > 0) {
        const { product } = preference;

        const { economicFields } = product;
        if (economicFields.productGroups) {
          state.productGroupPreference = economicFields.productGroups.value
            ? {
                name: economicFields.productGroups.name,
                value: parseInt(economicFields.productGroups.value),
              }
            : undefined;
        }

        if (product?.generateInvoice) {
          state.preferenceForProducts =
            product.generateInvoice?.create === true
              ? "useDefaultProduct"
              : "doNotGenerateInvoice";

          state.defaultProductPreference = product.generateInvoice?.mapping
            ?.ecoDefaultProduct?.value
            ? product.generateInvoice.mapping.ecoDefaultProduct
            : undefined;
        }

        if (product?.filterOptions) {
          state.shopifyProductFilterPreference = product.filterOptions
            ?.filterSHProperty?.value
            ? product.filterOptions.filterSHProperty
            : undefined;
          state.economicProductFilterPreference = product.filterOptions
            ?.filterEcoProperty?.value
            ? product.filterOptions.filterEcoProperty
            : undefined;
        }

        state.preferenceForSearchParameters =
          product.productSearchParameter === true
            ? "selectSearchParameters"
            : "setCustomMappings";

        if (product?.createProduct) {
          state.preferenceForProducts = product?.createProduct?.create
            ? "findCreateProduct"
            : product?.generateInvoice?.create
            ? "useDefaultProduct"
            : "doNotGenerateInvoice";
          state.preferenceForCreatingProduct =
            product?.createProduct?.customProduct?.useEcoDefaultProduct;
          state.defaultProductForCreating = product?.createProduct
            ?.customProduct?.mapping?.ecoDefaultProduct?.value
            ? product.createProduct.customProduct.mapping.ecoDefaultProduct
            : undefined;
        }
      }

      if (
        ((state.preferenceForProducts === "useDefaultProduct" &&
          state.defaultProductPreference) ||
          state.preferenceForProducts === "doNotGenerateInvoice" ||
          (state.preferenceForProducts === "findCreateProduct" &&
            (!state.preferenceForCreatingProduct ||
              (state.preferenceForCreatingProduct &&
                state.defaultProductForCreating)))) &&
        ((state.preferenceForSearchParameters === "selectSearchParameters" &&
          state.shopifyProductFilterPreference &&
          state.economicProductFilterPreference) ||
          (state.preferenceForSearchParameters === "setCustomMappings" &&
            mapping?.products?.length > 0))
      ) {
        state.isProductSyncRulesSaved = true;
      }
    });

    builder.addCase(onDeleteExistingMapping, (state, actions) => {
      if (
        actions.payload.mappingsFor &&
        actions.payload.mappingsFor === "products"
      ) {
        state.isProductSyncRulesSaved = false;
      }
    });

    builder.addCase(onSaveEditedMappings, (state, actions) => {
      if (
        actions.payload.mappingsFor &&
        actions.payload.mappingsFor === "products"
      ) {
        state.isProductSyncRulesSaved = false;
      }
    });

    builder.addCase(onAddShFieldsToCustomMappings, (state, actions) => {
      if (
        actions.payload.mappingsFor &&
        actions.payload.mappingsFor === "products"
      ) {
        state.isProductSyncRulesSaved = false;
      }
    });
  },
});

export const {
  onChangePreferenceForProducts,
  onChangePreferenceForSearchParameters,
  onChangePreferenceForCreatingProduct,
  onChangeProductPreference,
  setIsProductSyncRulesSaved,
} = productSlice.actions;

export default productSlice.reducer;
