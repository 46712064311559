import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import { fetchStatus, setShowErrorModal } from "./preferenceSlice";

const initialState = {
  searchInput: "",
  searchResultsArray: [],
  selectShopifyPreference: undefined,
  currentlyEditing: undefined,
  isEditMapping: false,
  isMappingsSaved: {
    customer: true,
    deal: true,
    products: true,
    productFields: true,
  },
  shopify: {
    loading: false,
    shopifyDropdownFields: {},
    error: "",
  },
  economic: {
    loading: false,
    economicDropdownFields: {},
    error: "",
  },
  mappings: {
    loading: false,
    defaultMappings: {
      customer: [],
      products: [],
      deal: [],
      productFields: [],
    },
    error: "",
  },
  deletedShopifyProperties: {
    customer: [],
    products: [],
    deal: [],
    productFields: [],
  },
  deletedEconomicProperties: {
    customer: [],
    products: [],
    deal: [],
    productFields: [],
  },
  activeAccordionItem: {
    customer: "0",
    deal: "0",
  },
  activeAccordionTitle: {
    customer: "General",
    deal: "Delivery address",
  },
  itemOffset: 0,
  slicedMappings: [],
  pageCount: 0,
  selectedIndex: 0,
};

export const fetchShopifyFields = createAsyncThunk(
  "mapping/fetchShopifyFields",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_SHOPIFY_FIELDS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const fetchEconomicFields = createAsyncThunk(
  "mapping/fetchEconomicFields",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_ECO_FIELDS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const mappingSlice = createSlice({
  name: "mapping",
  initialState,
  reducers: {
    updatePageCount(state, { payload }) {
      state.pageCount = payload.count;
    },

    updateItemOffset(state, { payload }) {
      state.itemOffset = payload.itemOffset;
    },

    updateSelectedIndex(state, { payload }) {
      state.selectedIndex = payload.selectedIndex;
    },

    updateSlicedMappings(state, { payload }) {
      state.slicedMappings = payload.mappings;
    },

    checkUnmappedFields(state, { payload }) {
      let newSet;
      if (
        payload.mappingsFor === "products" ||
        payload.mappingsFor === "productFields"
      ) {
        newSet = new Set(
          state.mappings.defaultMappings[payload.mappingsFor].map(
            (mappedItem) => mappedItem.ECO_FieldName_CRF
          )
        );
      } else {
        newSet = new Set();

        Object.keys(
          state.mappings.defaultMappings[payload.mappingsFor]
        ).forEach((key) => {
          if (
            state.mappings.defaultMappings[payload.mappingsFor][key].length > 0
          ) {
            const mappedItems = state.mappings.defaultMappings[
              payload.mappingsFor
            ][key].map((mappedItem) => mappedItem.ECO_FieldName_CRF);
            mappedItems.forEach((item) => {
              newSet.add(item);
            });
          }
        });
      }
      const mappingsForKey =
        payload.mappingsFor === "productFields"
          ? "productFieldMappings"
          : payload.mappingsFor;
      state.economic.economicDropdownFields[mappingsForKey].forEach(
        (ecoProperty) => {
          if (!newSet.has(ecoProperty.CRF)) {
            if (payload.mappingsFor === "products") {
              state.mappings.defaultMappings[payload.mappingsFor].push({
                ECO_FieldName_CRF: ecoProperty.CRF,
                ECO_FieldName_HRF: ecoProperty.HRF,
                ECO_FieldType: ecoProperty.type,
                ECO_Field_Description: ecoProperty.description,
                ECO_Field_Required: ecoProperty.required,
                SH_Fields: [],
                id: `${ecoProperty.CRF}-${payload.mappingsFor}`,
              });
            } else if (payload.mappingsFor === "productFields") {
              state.mappings.defaultMappings[payload.mappingsFor].push({
                ECO_FieldName_CRF: ecoProperty.CRF,
                ECO_FieldName_HRF: ecoProperty.HRF,
                ECO_FieldType: ecoProperty.type,
                ECO_Field_Description: ecoProperty.description,
                ECO_Field_Required: ecoProperty.required,
                SH_FieldName_CRF: undefined,
                SH_FieldName_HRF: undefined,
                SH_FieldType: undefined,
                SH_Field_Description: undefined,
                id: `${ecoProperty.CRF}-${payload.mappingsFor}`,
              });
            } else {
              const subCategory = ecoProperty?.accordion;
              state.mappings.defaultMappings[payload.mappingsFor][
                subCategory
              ].push({
                Accordion: subCategory,
                ECO_FieldName_CRF: ecoProperty.CRF,
                ECO_FieldName_HRF: ecoProperty.HRF,
                ECO_FieldType: ecoProperty.type,
                ECO_Field_Description: ecoProperty.description,
                ECO_Field_Required: ecoProperty.required,
                SH_FieldName_CRF: undefined,
                SH_FieldName_HRF: undefined,
                SH_FieldType: undefined,
                SH_Field_Description: undefined,
                id: `${ecoProperty.CRF}-${payload.mappingsFor}`,
              });
            }
          }
        }
      );
    },

    storeActiveAccordion(state, { payload }) {
      state.activeAccordionItem[payload.mappingsFor] = payload.activeAccordion;
      state.isEditMapping = false;
      state.currentlyEditing = "";
      state.searchInput = "";
      state.searchResultsArray = [];
    },

    storeActiveAccordionTitle(state, { payload }) {
      state.activeAccordionTitle[payload.mappingsFor] = payload.accordionTitle;
    },

    setShopifyPreference(state, { payload }) {
      state.selectShopifyPreference = payload.selectedOption;
    },

    onEditMappings(state, { payload }) {
      state.currentlyEditing = payload.id;
      state.isEditMapping = true;
    },

    onSearchMappings(state, { payload }) {
      const { mappingsFor, enteredInput } = payload;

      state.searchInput = enteredInput;
      state.searchResultsArray = [];

      if (state.searchInput !== "") {
        (mappingsFor === "products" || mappingsFor === "productFields"
          ? state.mappings.defaultMappings[mappingsFor]
          : state.mappings.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ]
        ).forEach((mappedItem) => {
          if (
            mappedItem.ECO_FieldName_HRF.toLowerCase().includes(
              enteredInput.toLowerCase()
            ) ||
            (mappedItem.SH_FieldName_HRF !== undefined &&
              mappedItem.SH_FieldName_HRF.toLowerCase().includes(
                enteredInput.toLowerCase()
              ))
          ) {
            state.searchResultsArray.push(mappedItem.id);
          }
        });
      }
    },

    onCancelMappings(state) {
      state.isEditMapping = false;
      state.currentlyEditing = undefined;
      state.selectShopifyPreference = undefined;
    },

    onDeleteExistingMapping(state, { payload }) {
      const { id, mappingsFor } = payload;

      const index = (
        mappingsFor === "products" || mappingsFor === "productFields"
          ? state.mappings.defaultMappings[mappingsFor]
          : state.mappings.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ]
      ).findIndex((mappedItem) => mappedItem.id === id);

      if (mappingsFor === "productFields") {
        state.mappings.defaultMappings[mappingsFor][index] = {
          ...state.mappings.defaultMappings[mappingsFor][index],
          SH_FieldName_CRF: undefined,
          SH_FieldName_HRF: undefined,
          SH_FieldType: undefined,
          SH_Field_Description: undefined,
        };
      } else if (mappingsFor === "products") {
        state.mappings.defaultMappings[mappingsFor][index] = {
          ...state.mappings.defaultMappings[mappingsFor][index],
          SH_Fields: [],
        };
        state.deletedEconomicProperties[mappingsFor].forEach((ecoProperty) => {
          if (
            ecoProperty ===
            state.mappings.defaultMappings[mappingsFor][index].ECO_FieldName_CRF
          ) {
            state.mappings.defaultMappings[mappingsFor].splice(index, 1);
          }
        });
      } else {
        state.mappings.defaultMappings[mappingsFor][
          state.activeAccordionTitle[mappingsFor]
        ][index] = {
          ...state.mappings.defaultMappings[mappingsFor][
            state.activeAccordionTitle[mappingsFor]
          ][index],
          SH_FieldName_CRF: undefined,
          SH_FieldName_HRF: undefined,
          SH_FieldType: undefined,
          SH_Field_Description: undefined,
        };

        if (mappingsFor === "deal") {
          state.mappings.defaultMappings[mappingsFor][
            state.activeAccordionTitle[mappingsFor]
          ][index] = {
            ...state.mappings.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ][index],
            SH_Field_For: undefined,
            SH_Accordion: undefined,
          };
        }
      }
      state.isMappingsSaved[mappingsFor] = false;
    },

    onSaveEditedMappings(state, { payload }) {
      const { id, mappingsFor } = payload;
      if (state.selectShopifyPreference !== "") {
        const index = (
          mappingsFor === "products" || mappingsFor === "productFields"
            ? state.mappings.defaultMappings[mappingsFor]
            : state.mappings.defaultMappings[mappingsFor][
                state.activeAccordionTitle[mappingsFor]
              ]
        ).findIndex((mappedItem) => mappedItem.id === id);

        if (mappingsFor === "products" || mappingsFor === "productFields") {
          state.mappings.defaultMappings[mappingsFor][index] = {
            ...state.mappings.defaultMappings[mappingsFor][index],
            SH_FieldName_CRF: state.selectShopifyPreference.CRF,
            SH_FieldName_HRF: state.selectShopifyPreference.HRF,
            SH_FieldType: state.selectShopifyPreference.type,
            SH_Field_Description: state.selectShopifyPreference.description,
          };
        } else {
          state.mappings.defaultMappings[mappingsFor][
            state.activeAccordionTitle[mappingsFor]
          ][index] = {
            ...state.mappings.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ][index],
            Accordion: state.activeAccordionTitle[mappingsFor],
            SH_FieldName_CRF: state.selectShopifyPreference.CRF,
            SH_FieldName_HRF: state.selectShopifyPreference.HRF,
            SH_FieldType: state.selectShopifyPreference.type,
            SH_Field_Description: state.selectShopifyPreference.description,
          };

          if (mappingsFor === "deal") {
            state.mappings.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ][index] = {
              ...state.mappings.defaultMappings[mappingsFor][
                state.activeAccordionTitle[mappingsFor]
              ][index],
              SH_Field_For: state.selectShopifyPreference.fieldsFor,
              SH_Accordion: state.selectShopifyPreference.accordion,
            };
          }
        }
      }

      state.selectShopifyPreference = undefined;
      state.isEditMapping = false;
      state.currentlyEditing = undefined;
      state.isMappingsSaved[mappingsFor] = false;
    },

    clearDefaultValues(state) {
      state.currentlyEditing = undefined;
      state.isEditMapping = false;
      state.selectShopifyPreference = undefined;
      state.searchInput = "";
      state.searchResultsArray = [];
      state.selectedIndex = 0;
      state.pageCount = 0;
      state.itemOffset = 0;
    },

    checkIsMappingsSaved(state, { payload }) {
      state.isMappingsSaved[payload.mappingsFor] = true;
    },

    validateShopifyProperties(state, { payload }) {
      const { mappingsFor } = payload;
      const shopifyDropdownKey =
        mappingsFor === "products"
          ? "products"
          : mappingsFor === "deal"
          ? "orderFields"
          : mappingsFor === "customer"
          ? "customerFields"
          : "productFieldMapping";

      state.deletedShopifyProperties[mappingsFor] = [];
      const newSet = new Set(
        (mappingsFor === "deal"
          ? [
              ...state.shopify.shopifyDropdownFields.customerFields,
              ...state.shopify.shopifyDropdownFields.orderFields,
            ]
          : state.shopify.shopifyDropdownFields[shopifyDropdownKey]
        ).map((shopifyProperty) => shopifyProperty.CRF)
      );

      if (mappingsFor === "products" || mappingsFor === "productFields") {
        state.mappings.defaultMappings[mappingsFor].forEach((mapping) => {
          if (
            mapping.SH_FieldName_CRF !== undefined &&
            !newSet.has(mapping.SH_FieldName_CRF)
          ) {
            state.deletedShopifyProperties[mappingsFor].push(
              mapping.SH_FieldName_CRF
            );
          }
        });
      } else {
        Object.keys(state.mappings.defaultMappings[mappingsFor]).forEach(
          (item) => {
            state.mappings.defaultMappings[mappingsFor][item].forEach(
              (mapping) => {
                if (
                  mapping.SH_FieldName_CRF !== undefined &&
                  !newSet.has(mapping.SH_FieldName_CRF)
                ) {
                  state.deletedShopifyProperties[mappingsFor].push(
                    mapping.SH_FieldName_CRF
                  );
                }
              }
            );
          }
        );
      }
    },
    validateEconomicProperties(state, { payload }) {
      const { mappingsFor } = payload;
      const mappingsForKey =
        mappingsFor === "productFields" ? "productFieldMappings" : mappingsFor;
      state.deletedEconomicProperties[mappingsFor] = [];
      const newSet = new Set(
        state.economic.economicDropdownFields[mappingsForKey].map(
          (economicProperty) => economicProperty.CRF
        )
      );
      if (mappingsFor === "products" || mappingsFor === "productFields") {
        state.mappings.defaultMappings[mappingsFor].forEach((mapping) => {
          if (
            mapping.ECO_FieldName_CRF !== undefined &&
            !newSet.has(mapping.ECO_FieldName_CRF)
          ) {
            state.deletedEconomicProperties[mappingsFor].push(
              mapping.ECO_FieldName_CRF
            );
          }
        });
      } else {
        Object.keys(state.mappings.defaultMappings[mappingsFor]).forEach(
          (item) => {
            state.mappings.defaultMappings[mappingsFor][item].forEach(
              (mapping) => {
                if (
                  mapping.ECO_FieldName_CRF !== undefined &&
                  !newSet.has(mapping.ECO_FieldName_CRF)
                ) {
                  state.deletedEconomicProperties[mappingsFor].push(
                    mapping.ECO_FieldName_CRF
                  );
                }
              }
            );
          }
        );
      }
    },

    onAddShFieldsToCustomMappings(state, { payload }) {
      const { checked, mappingsFor, mappedItem, selectedValue } = payload;

      const index = state.mappings.defaultMappings[mappingsFor].findIndex(
        (mapped) => mapped.id === mappedItem.id
      );

      if (index !== -1) {
        if (checked === true) {
          state.mappings.defaultMappings[mappingsFor][index].SH_Fields.push({
            SH_FieldName_HRF: selectedValue.HRF,
            SH_FieldName_CRF: selectedValue.CRF,
            SH_FieldType: selectedValue.type,
            SH_Field_Description: selectedValue.description,
            SH_ProductId: selectedValue.productId,
          });
        } else {
          const shIndex = state.mappings.defaultMappings[mappingsFor][
            index
          ].SH_Fields.findIndex(
            (shField) => shField.SH_ProductId === selectedValue.productId
          );

          if (shIndex !== -1) {
            state.mappings.defaultMappings[mappingsFor][index].SH_Fields.splice(
              shIndex,
              1
            );
          }
        }
        state.isMappingsSaved.products = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShopifyFields.pending, (state, action) => {
      if (!state.shopify.loading) {
        state.shopify.loading = true;
      }
    });
    builder.addCase(fetchShopifyFields.fulfilled, (state, action) => {
      if (state.shopify.loading) {
        state.shopify.loading = false;
        state.shopify.shopifyDropdownFields = action.payload;
        state.shopify.error = "";

        if (state.shopify.shopifyDropdownFields.customerFields) {
          state.shopify.shopifyDropdownFields.customerFields.push({
            HRF: "Do not add it anywhere",
            CRF: "doNotAdd",
            type: "string",
            description: "",
          });
        }
      }
    });
    builder.addCase(fetchShopifyFields.rejected, (state, action) => {
      if (state.shopify.loading) {
        state.shopify.loading = false;
        state.shopify.error = action.error.message ?? "";
        state.shopify.shopifyDropdownFields = {};
      }
    });

    builder.addCase(fetchEconomicFields.pending, (state, action) => {
      if (!state.economic.loading) {
        state.economic.loading = true;
      }
    });
    builder.addCase(fetchEconomicFields.fulfilled, (state, action) => {
      if (state.economic.loading) {
        state.economic.loading = false;
        state.economic.economicDropdownFields = action.payload;
        state.economic.error = "";
      }
    });
    builder.addCase(fetchEconomicFields.rejected, (state, action) => {
      if (state.economic.loading) {
        state.economic.loading = false;
        state.economic.error = action.error.message ?? "";
        state.economic.economicDropdownFields = {};
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { mapping } = action.payload;

      const { customer, deal, products, productFieldMappings } = mapping;

      state.mappings.defaultMappings.customer = customer ?? [];
      state.mappings.defaultMappings.deal = deal ?? [];
      state.mappings.defaultMappings.products = products ?? [];
      state.mappings.defaultMappings.productFields = productFieldMappings ?? [];

      const dummyObject = {
        customer: { General: [], Additional: [] },
        deal: {
          "Delivery address": [],
          "Notes and references": [],
          "Billing address": [],
        },
        products: [],
        productFields: [],
      };

      Object.keys(state.mappings.defaultMappings).forEach((category) => {
        if (category === "products" || category === "productFields") {
          state.mappings.defaultMappings[category].forEach((eachMapping) => {
            eachMapping = {
              ...eachMapping,
              id: `${eachMapping.ECO_FieldName_CRF}-${category}`,
            };
            dummyObject[category].push(eachMapping);
          });
        } else {
          state.mappings.defaultMappings[category].forEach((eachMapping) => {
            const subCategory = eachMapping.Accordion;
            eachMapping = {
              ...eachMapping,
              id: `${eachMapping.ECO_FieldName_CRF}-${category}`,
            };
            dummyObject[category][subCategory].push(eachMapping);
          });
        }
      });
      state.mappings.defaultMappings = dummyObject;
    });
  },
});

export const {
  checkUnmappedFields,
  setShopifyPreference,
  onEditMappings,
  onSearchMappings,
  onCancelMappings,
  onDeleteExistingMapping,
  onSaveEditedMappings,
  clearDefaultValues,
  checkIsMappingsSaved,
  updateItemOffset,
  updatePageCount,
  updateSelectedIndex,
  updateSlicedMappings,
  validateShopifyProperties,
  validateEconomicProperties,
  storeActiveAccordion,
  storeActiveAccordionTitle,
  onAddShFieldsToCustomMappings,
} = mappingSlice.actions;

export default mappingSlice.reducer;
