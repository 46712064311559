import React, { useEffect, useState } from "react";
import {
  Col,
  Table,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Button } from "@shopify/polaris";

import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaCheck, FaCaretDown, FaCaretRight } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { BsArrowRightShort } from "react-icons/bs";

import axios from "axios";

import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";

import {
  setShowSuccessModal,
  setShowErrorModal,
} from "../../store/slices/preferenceSlice";

import { useCmsHook } from "../../helpers/cmsHook";

import {
  checkUnmappedFields,
  onSearchMappings,
  onDeleteExistingMapping,
  onSaveEditedMappings,
  onEditMappings,
  onCancelMappings,
  validateShopifyProperties,
  validateEconomicProperties,
  checkIsMappingsSaved,
  storeActiveAccordion,
  storeActiveAccordionTitle,
} from "../../store/slices/mappingSlice";

import MappingDropdown from "./MappingDropdown";
import SearchResultsNotFound from "./SearchResultsNotFound";
import Pagination from "./Pagination";
import CustomProductMappingDropdown from "./CustomProductMappingDropdown";
import { getUrl } from "../../helpers/url";

import CreateNewProperty from "./CreateNewProperty";

const MappingWindow = ({
  mappings,
  ecoFields,
  shopifyFields,
  mappingsFor,
  shopifyCustomerFields,
  notificationPath,
  cmsPath,
}) => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  // mapping states
  const {
    searchInput,
    searchResultsArray,
    currentlyEditing,
    selectShopifyPreference,
    isEditMapping,
    slicedMappings,
    deletedShopifyProperties,
    deletedEconomicProperties,
    activeAccordionItem,
    activeAccordionTitle,
  } = useSelector((state) => state.mapping);

  const sendMappings = () => {
    if (mappingsFor === "products" && mappings.length > 10) {
      return slicedMappings;
    } else if (mappingsFor === "products" && mappings.length <= 10) {
      return mappings;
    } else if (mappingsFor === "productFields") {
      return mappings;
    } else {
      return mappings[activeAccordionTitle[mappingsFor]];
    }
  };

  const mappedList =
    sendMappings()?.map((mappedItem) => {
      if (
        searchResultsArray.includes(mappedItem.id) ||
        searchInput.length === 0 ||
        !searchInput
      )
        return renderMappings({
          currentlyEditing,
          mappedItem,
          shopifyFields,
          selectShopifyPreference,
          isEditMapping,
          mappingsFor,
          mappings: mappingsFor === "products" ? slicedMappings : mappings,
          dispatch,
          deletedShopifyProperties,
          deletedEconomicProperties,
          shopifyCustomerFields,
        });
      return null;
    }) ?? [];

  useEffect(() => {
    if (ecoFields && ecoFields.length > 0 && mappings) {
      dispatch(checkUnmappedFields({ mappingsFor }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings, ecoFields, shopifyFields]);

  useEffect(() => {
    if (mappings && shopifyFields && shopifyFields.length > 0) {
      dispatch(validateShopifyProperties({ mappingsFor }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopifyFields, mappingsFor, mappings]);

  useEffect(() => {
    if (mappings && ecoFields && ecoFields.length > 0) {
      dispatch(validateEconomicProperties({ mappingsFor }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecoFields, mappingsFor, mappings]);

  return (
    <div className="my-4 mapping-window">
      {cmsInstance && cmsInstance.showRichText(cmsPath)}
      <div className="my-3 d-flex justify-content-between">
        <div className="dropdown-search-container px-2 w-25">
          <span>
            <GoSearch size={20} className="me-2" />
          </span>
          <input
            type="search"
            value={searchInput}
            placeholder="Search"
            onChange={(e) =>
              dispatch(
                onSearchMappings({ enteredInput: e.target.value, mappingsFor })
              )
            }
            className="search-input"
          />
        </div>
        {mappingsFor !== "products" && (
          <div>
            <CreateNewProperty mappingsFor={mappingsFor} />
          </div>
        )}
      </div>

      {mappingsFor === "products" || mappingsFor === "productFields" ? (
        <>
          {searchResultsArray.length === 0 && searchInput ? (
            <SearchResultsNotFound />
          ) : (
            <Table responsive className="mapping-table">
              <thead className="mapping-table-header">
                <tr>
                  <th
                    style={{ width: "350px", fontFamily: "Inter" }}
                    className="ps-4"
                  >
                    e-conomic
                  </th>
                  <th style={{ width: "200px" }}></th>
                  <th
                    style={{
                      width: "350px",
                      fontFamily: "Inter",
                      paddingLeft: `${
                        mappingsFor === "products" ? "30px" : ""
                      }`,
                    }}
                  >
                    Shopify
                  </th>
                  <th
                    className="pe-4"
                    style={{
                      textAlign: "center",
                      width: "100px",
                      fontFamily: "Inter",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody className="mapping-table-body">{mappedList}</tbody>
            </Table>
          )}
        </>
      ) : (
        <>
          <Accordion
            defaultActiveKey={activeAccordionItem[mappingsFor]}
            onSelect={(eventKey) =>
              dispatch(
                storeActiveAccordion({ mappingsFor, activeAccordion: eventKey })
              )
            }
          >
            {mappings &&
              Object.keys(mappings).map((accTitle, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={index.toString()}
                  className="mt-3"
                >
                  <Accordion.Header
                    onClick={() =>
                      dispatch(
                        storeActiveAccordionTitle({
                          mappingsFor,
                          accordionTitle: accTitle,
                        })
                      )
                    }
                  >
                    {activeAccordionItem[mappingsFor] === index.toString() ? (
                      <FaCaretDown className="me-1" size={12} />
                    ) : (
                      <FaCaretRight className="me-1" size={12} />
                    )}
                    <span className="accordion-title">{accTitle}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {searchInput.length > 1 &&
                    searchResultsArray.length === 0 ? (
                      <SearchResultsNotFound />
                    ) : (
                      <Table responsive className="mapping-table">
                        <thead className="mapping-table-header">
                          <tr>
                            <th
                              style={{ width: "350px", fontFamily: "Inter" }}
                              className="ps-4"
                            >
                              e-conomic
                            </th>
                            <th style={{ width: "200px" }}></th>
                            <th style={{ width: "350px", fontFamily: "Inter" }}>
                              Shopify
                            </th>
                            <th
                              className="pe-4"
                              style={{
                                textAlign: "right",
                                width: "350px",
                                fontFamily: "Inter",
                              }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody className="mapping-table-body">
                          {mappedList}
                        </tbody>
                      </Table>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </>
      )}

      <div className="d-flex flex-row justify-content-end mt-4">
        {(deletedShopifyProperties[mappingsFor] &&
          deletedShopifyProperties[mappingsFor].length > 0) ||
        (deletedEconomicProperties[mappingsFor] &&
          deletedEconomicProperties[mappingsFor].length > 0) ? (
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip style={{ fontSize: "12px" }}>
                {deletedShopifyProperties[mappingsFor] &&
                deletedShopifyProperties[mappingsFor].length > 0
                  ? "Please remap the deleted Shopify fields."
                  : "You have deleted the product in the e-conomic please delete the mapped deleted product and save the mappings."}
              </Tooltip>
            }
          >
            <div>
              <Button
                style={{ opacity: 0.8, cursor: "not-allowed" }}
                className="sync-settings-button"
                disabled={isEditMapping}
              >
                Save changes
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <Button
            className="sync-settings-button"
            loading={isLoading}
            primary
            onClick={() =>
              onSaveMappings({
                setIsLoading,
                searchParams,
                mappings,
                mappingsFor,
                dispatch,
                notificationPath,
                cmsInstance,
              })
            }
          >
            Save changes
          </Button>
        )}
      </div>

      {mappingsFor === "products" && mappings.length > 10 && (
        <Pagination mappingsFor={mappingsFor} mappings={mappings} />
      )}
    </div>
  );
};

export default MappingWindow;

const renderMappings = ({
  currentlyEditing,
  mappedItem,
  shopifyFields,
  selectShopifyPreference,
  isEditMapping,
  mappingsFor,
  mappings,
  dispatch,
  deletedShopifyProperties,
  deletedEconomicProperties,
  shopifyCustomerFields,
}) => {
  if (mappedItem.id === currentlyEditing && isEditMapping) {
    return (
      <tr style={{ verticalAlign: "middle" }} key={mappedItem.id}>
        <td className="ps-4" style={{ fontFamily: "Inter" }}>
          {mappedItem.ECO_FieldName_HRF}
        </td>
        <td
          style={{ textAlign: `${mappingsFor === "products" ? "center" : ""}` }}
        >
          <BsArrowRightShort
            style={{
              transform: "scaleX(-1)",
            }}
            className="mt-auto"
            size={25}
          />
        </td>
        <td>
          <Col lg={9}>
            <MappingDropdown
              mappings={mappings}
              shopifyDropdownFields={shopifyFields}
              mappedItem={mappedItem}
              mappingsFor={mappingsFor}
              shopifyCustomerFields={shopifyCustomerFields}
            />
          </Col>
        </td>
        <td style={{ textAlign: "right" }}>
          <div>
            {selectShopifyPreference && (
              <button
                onClick={() => {
                  dispatch(
                    onSaveEditedMappings({ id: mappedItem.id, mappingsFor })
                  );
                }}
                className="save-mapping-icon"
                style={{ marginRight: "6px" }}
              >
                <FaCheck size={14} />
              </button>
            )}
            <button
              onClick={() => {
                dispatch(onCancelMappings());
              }}
              className="save-mapping-icon"
              style={{ marginRight: "14px" }}
            >
              <ImCross size={14} />
            </button>
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr style={{ verticalAlign: "middle" }} key={mappedItem.id}>
        <td className="ps-4">
          <span>
            <span style={{ fontFamily: "Inter" }}>
              {mappedItem.ECO_FieldName_HRF}
            </span>
            <br></br>
            <span
              style={{
                color: "#7a91ab",
                fontSize: "12px",
                fontFamily: "Inter",
              }}
            >
              {mappedItem.ECO_Field_Description}
            </span>
          </span>

          <br></br>
          {mappedItem.ECO_FieldName_CRF !== undefined &&
            deletedEconomicProperties[mappingsFor].includes(
              mappedItem.ECO_FieldName_CRF
            ) && <span className="error-text">(property deleted)</span>}
        </td>
        <td
          style={{
            textAlign: `${mappingsFor === "products" ? "center" : ""}`,
          }}
        >
          <BsArrowRightShort
            style={{
              transform: "scaleX(-1)",
            }}
            className="mt-auto"
            size={25}
          />
        </td>
        {mappingsFor === "products" ? (
          <td>
            {shopifyFields && (
              <CustomProductMappingDropdown
                mappedItem={mappedItem}
                mappings={mappings}
                shopifyDropdownFields={shopifyFields}
                mappingsFor={mappingsFor}
                deletedShopifyFields={deletedShopifyProperties[mappingsFor]}
              />
            )}
          </td>
        ) : (
          <td>
            {mappedItem.SH_FieldName_HRF === undefined ? (
              <span
                style={{
                  cursor: "pointer",
                  color: "#7a91ab",
                  fontFamily: "Inter",
                }}
              >
                Not Mapped
              </span>
            ) : (
              <span>
                <span style={{ fontFamily: "Inter" }}>
                  {mappedItem.SH_FieldName_HRF}
                </span>
                <br></br>
                <span
                  style={{
                    color: "#7a91ab",
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {mappedItem.SH_Field_Description}
                </span>
              </span>
            )}
            <br />
            {mappedItem.SH_FieldName_CRF !== undefined &&
              deletedShopifyProperties[mappingsFor].includes(
                mappedItem.SH_FieldName_CRF
              ) && <span className="error-text">(property deleted)</span>}
          </td>
        )}

        <td
          style={{
            textAlign: `${mappingsFor === "products" ? "center" : "right"}`,
          }}
        >
          <div>
            {mappingsFor !== "products" && (
              <button
                onClick={() => {
                  dispatch(
                    onEditMappings({
                      id: mappedItem.id,
                    })
                  );
                }}
                className="save-mapping-icon"
                disabled={deletedEconomicProperties[mappingsFor].includes(
                  mappedItem.ECO_FieldName_CRF
                )}
                style={{
                  marginRight: "6px",
                  opacity: `${
                    deletedEconomicProperties[mappingsFor].includes(
                      mappedItem.ECO_FieldName_CRF
                    )
                      ? "0.5"
                      : ""
                  }`,
                  cursor: `${
                    deletedEconomicProperties[mappingsFor].includes(
                      mappedItem.ECO_FieldName_CRF
                    )
                      ? "not-allowed"
                      : ""
                  }`,
                }}
              >
                <img src={editIcon} alt="edit icon" />
              </button>
            )}

            <button
              disabled={
                mappedItem.ECO_Field_Required === true ||
                (mappingsFor !== "products" &&
                  mappedItem.SH_FieldName_CRF === undefined) ||
                (mappingsFor === "products" &&
                  ((mappedItem.SH_Fields &&
                    mappedItem.SH_Fields.length === 0) ||
                    !mappedItem?.SH_Fields))
              }
              onClick={() =>
                dispatch(
                  onDeleteExistingMapping({ id: mappedItem.id, mappingsFor })
                )
              }
              className="save-mapping-icon"
              style={{
                marginRight: "14px",
                opacity: `${
                  mappedItem.ECO_Field_Required === true ||
                  (mappingsFor !== "products" &&
                    mappedItem.SH_FieldName_CRF === undefined) ||
                  (mappingsFor === "products" &&
                    ((mappedItem.SH_Fields &&
                      mappedItem.SH_Fields.length === 0) ||
                      !mappedItem?.SH_Fields))
                    ? "0.5"
                    : ""
                }`,
                cursor: `${
                  mappedItem.ECO_Field_Required === true ||
                  (mappingsFor !== "products" &&
                    mappedItem.SH_FieldName_CRF === undefined) ||
                  (mappingsFor === "products" &&
                    ((mappedItem.SH_Fields &&
                      mappedItem.SH_Fields.length === 0) ||
                      !mappedItem?.SH_Fields))
                    ? "not-allowed"
                    : ""
                }`,
              }}
            >
              <img src={deleteIcon} alt="delete icon" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
};

export const onSaveMappings = async ({
  setIsLoading,
  searchParams,
  mappings,
  mappingsFor,
  dispatch,
  notificationPath,
  cmsInstance,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");
    const savedMappings = [];
    if (mappingsFor !== "products" && mappingsFor !== "productFields") {
      Object.keys(mappings).forEach((key) => {
        mappings[key].forEach((item) => {
          savedMappings.push({ ...item });
        });
      });
    }

    const validMappings = [];
    const mappingsObj =
      mappingsFor === "customer" || mappingsFor === "deal"
        ? savedMappings
        : mappings;

    mappingsObj.forEach((mappedItem) => {
      let newMapping = {};
      if (
        mappedItem.SH_FieldName_CRF !== undefined &&
        mappingsFor !== "products"
      ) {
        newMapping = {
          ECO_FieldName_CRF: mappedItem.ECO_FieldName_CRF,
          SH_FieldName_HRF: mappedItem.SH_FieldName_HRF,
          SH_FieldName_CRF: mappedItem.SH_FieldName_CRF,
          SH_Field_Description: mappedItem.SH_Field_Description,
          ECO_Field_Description: mappedItem.ECO_Field_Description,
          ECO_FieldType: mappedItem.ECO_FieldType,
          SH_FieldType: mappedItem.SH_FieldType,
          ECO_FieldName_HRF: mappedItem.ECO_FieldName_HRF,
          SH_Field_For: mappedItem.SH_Field_For,
          SH_Accordion: mappedItem.SH_Accordion,
          Accordion: mappedItem.Accordion,
        };
        if (mappedItem.ECO_Field_Required) {
          newMapping.ECO_Field_Required = mappedItem.ECO_Field_Required;
        }
      } else if (
        mappingsFor === "products" &&
        mappedItem.SH_Fields &&
        mappedItem.SH_Fields.length > 0
      ) {
        newMapping = {
          ECO_FieldName_CRF: mappedItem.ECO_FieldName_CRF,
          ECO_FieldName_HRF: mappedItem.ECO_FieldName_HRF,
          ECO_Field_Description: mappedItem.ECO_Field_Description,
          ECO_FieldType: mappedItem.ECO_FieldType,
          SH_Fields: mappedItem.SH_Fields,
        };
        if (mappedItem.ECO_Field_Required) {
          newMapping.ECO_Field_Required = mappedItem.ECO_Field_Required;
        }
      }
      if (Object.keys(newMapping).length > 0) {
        validMappings.push(newMapping);
      }
    });
    await axios.post(getUrl("REACT_APP_SAVE_MAPPINGS"), {
      shop,
      mappings: validMappings,
      mappingObj:
        mappingsFor === "productFields" ? "productFieldMappings" : mappingsFor,
    });

    dispatch(checkIsMappingsSaved({ mappingsFor }));
    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent[notificationPath].message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
