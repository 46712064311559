import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import { Spinner } from "@shopify/polaris";
import { Spinner } from "react-bootstrap";

import MappingWindow from "../mapping/MappingWindow";

function FieldMappings() {
  const [filteredFields, setFilteredFields] = useState([]);
  const {
    mappings: {
      defaultMappings: { deal: mappings },
    },
    economic: {
      loading: economicLoading,
      economicDropdownFields: { deal: ecoFields },
    },
    shopify: {
      loading: shopifyLoading,
      shopifyDropdownFields: {
        orderFields: shopifyFields,
        customerFields: customerDropdownFields,
      },
    },
  } = useSelector((state) => state.mapping);

  useEffect(() => {
    if (customerDropdownFields && customerDropdownFields.length > 0) {
      const filtered = customerDropdownFields.filter(
        (field) => field.CRF !== "doNotAdd"
      );
      setFilteredFields(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDropdownFields]);

  return (
    <div>
      {shopifyLoading || economicLoading ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div>
          {ecoFields &&
            ecoFields.length > 0 &&
            shopifyFields &&
            shopifyFields.length > 0 && (
              <MappingWindow
                mappings={mappings}
                ecoFields={ecoFields}
                shopifyFields={shopifyFields}
                shopifyCustomerFields={filteredFields}
                mappingsFor="deal"
                notificationPath="invoiceFieldMappings"
                cmsPath="cmsContent.syncSettings.invoice.fieldMappings"
              />
            )}
        </div>
      )}
    </div>
  );
}

export default FieldMappings;
