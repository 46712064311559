import "./App.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Frame } from "@shopify/polaris";

import Setup from "./pages/Setup";
import ConnectEconomic from "./pages/ConnectEconomic";
import SettingsPage from "./pages/SettingsPage";
import SyncSettings from "./pages/SyncSettings";
import Redirect from "./pages/Redirect";
import ConfirmationPage from "./pages/ConfirmationPage";
import SetupGuide from "./pages/SetupGuide";
import Subscription from "./pages/Subscription";
import ManagePlan from "./pages/ManagePlan";
import AutoSaveModal from "./components/modals/AutoSaveModal";
import Retrigger from "./pages/Retrigger";
import Connect from "./pages/Connect";
import CmsNotifications from "./helpers/CmsNotifications";
import { CmsProvider } from "./helpers/cmsHook";
import SuccessToast from "./components/modals/SuccessToast";
import ErrorToast from "./components/modals/ErrorToast";

function App() {
  return (
    <Frame>
      <CmsProvider>
        <Router>
          <SuccessToast />
          <ErrorToast />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <AutoSaveModal />
          <Routes>
            <Route path="/setup" element={<Setup />} />
            <Route path="/connect-economic" element={<ConnectEconomic />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/syncSettings" element={<SyncSettings />} />
            <Route path="/confirmation" element={<ConfirmationPage />} />
            <Route path="/redirect" element={<Redirect />} />
            <Route path="/setup-guide" element={<SetupGuide />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/manage-plan" element={<ManagePlan />} />
            <Route path="/retrigger" element={<Retrigger />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/edit-notifications" element={<CmsNotifications />} />
          </Routes>
        </Router>
      </CmsProvider>
    </Frame>
  );
}

export default App;
