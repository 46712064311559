import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import { BsArrowRight } from "react-icons/bs";

import FieldsDropdown from "../FieldsDropdown";

import {
  onChangeShopifyCustomerNumber,
  setIsContactSyncRulesAndDefaultMappings,
} from "../../store/slices/contactSlice";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

import { Button } from "@shopify/polaris";

import { getUrl } from "../../helpers/url";

import { useCmsHook } from "../../helpers/cmsHook";

const SyncRules = () => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  //const [customerFields, setCustomerFields] = useState([]);

  const toggleCustomerNumber = (selectedValue) => {
    return shopifyCustomerNumberFields.find(
      (field) => field.value === selectedValue
    )?.title;
  };

  const {
    economicFilterPreference,
    shopifyFilterPreference,
    shopifyCustomerNumberFields,
    shopifyCustomerNumberPreference,
    economicCustomerPreference,
    shopifyCustomerPreference,
    economicCustomerDropdownFields,
  } = useSelector((state) => state.contact);

  const {
    shopify: {
      loading: shopifyLoading,
      shopifyDropdownFields: {
        customerFields: shopifyCustomerFields,
        shopifyFilterProperties: filterProperties,
        customFields,
      },
    },
  } = useSelector((state) => state.mapping);

  const {
    ecoDefaultFields: {
      loading: economicLoading,
      ecoDefaultFields: { economicFilterProperties: economicCustomerFields },
    },
  } = useSelector((state) => state.contact);

  // useEffect(() => {
  //   if (shopifyCustomerFields) {
  //     if (shopifyCustomerNumberPreference.value === "shopify") {
  //       const filteredProperties = shopifyCustomerFields.filter(
  //         (field) => field.CRF !== "doNotAdd"
  //       );
  //       setCustomerFields(filteredProperties);
  //     } else if (shopifyCustomerNumberPreference.value === "economic") {
  //       setCustomerFields(shopifyCustomerFields);
  //     }
  //   }
  // }, [shopifyCustomerNumberPreference.value, shopifyCustomerFields]);

  return (
    <div>
      {shopifyLoading || economicLoading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.syncRules.section1"
              )}
            {filterProperties &&
              filterProperties.length > 0 &&
              economicCustomerFields &&
              economicCustomerFields.length > 0 && (
                <Row className="mt-3 d-flex flex-row align-items-center">
                  <Col md={5} lg={3}>
                    <p className="dropdown-header">Shopify</p>
                    <FieldsDropdown
                      fieldsData={filterProperties}
                      dropdownFor="shopifyFilterDropdown"
                      selectedPreference={shopifyFilterPreference}
                    />
                  </Col>
                  <Col
                    sm={1}
                    className="mt-4 px-0 d-flex flex-row justify-content-center"
                    style={{ width: "40px" }}
                  >
                    <BsArrowRight size={16} />
                  </Col>
                  <Col md={5} lg={3}>
                    <p className="dropdown-header">e-conomic</p>
                    <FieldsDropdown
                      fieldsData={economicCustomerFields}
                      dropdownFor="economicFilterDropdown"
                      selectedPreference={economicFilterPreference}
                    />
                  </Col>
                </Row>
              )}
          </div>
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.syncRules.section2.text"
              )}
            <Col lg={4} md={6} className="mt-3">
              <Dropdown className="dropdown">
                <Dropdown.Toggle className="dropdown-box my-2">
                  {toggleCustomerNumber(shopifyCustomerNumberPreference.value)}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  renderOnMount={true}
                  popperConfig={{ strategy: "fixed" }}
                  className="dropdown-menu-container"
                >
                  {shopifyCustomerNumberFields &&
                    shopifyCustomerNumberFields.map((fieldItem) => (
                      <Dropdown.Item
                        key={fieldItem.value}
                        className="dropdown-item"
                        onClick={() =>
                          dispatch(
                            onChangeShopifyCustomerNumber({
                              selectedOption: fieldItem.value,
                            })
                          )
                        }
                      >
                        {fieldItem.title}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {shopifyCustomerNumberPreference?.value === "economic" &&
              cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.syncRules.section2.renderText.createCustomer"
              )}

            {shopifyCustomerNumberPreference?.value === "shopify" &&
              cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.syncRules.section2.renderText.takeCustomer"
              )}
          </div>
          <div className="sync-settings-card">
            {shopifyCustomerNumberPreference?.value === "economic" &&
              cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.syncRules.section3.createCustomer"
              )}
            {shopifyCustomerNumberPreference?.value === "shopify" &&
              cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.syncRules.section3.takeCustomer"
              )}
            {shopifyCustomerFields &&
              shopifyCustomerFields.length > 0 &&
              economicCustomerDropdownFields &&
              economicCustomerDropdownFields.length > 0 && (
                <Row className="mt-3 d-flex flex-row align-items-center">
                  <Col md={5} lg={3}>
                    <p className="dropdown-header">Shopify</p>
                    <FieldsDropdown
                      fieldsData={customFields}
                      dropdownFor="shopifyCustomerNumber"
                      selectedPreference={shopifyCustomerPreference}
                    />
                  </Col>
                  <Col
                    sm={1}
                    className="mt-4 px-0 d-flex flex-row justify-content-center"
                    style={{ width: "40px" }}
                  >
                    <BsArrowRight
                      size={16}
                      style={{
                        transform:
                          shopifyCustomerNumberPreference.value === "economic"
                            ? "scaleX(-1)"
                            : "",
                      }}
                    />
                  </Col>
                  <Col md={5} lg={3}>
                    <p className="dropdown-header">e-conomic</p>
                    <FieldsDropdown
                      fieldsData={economicCustomerDropdownFields}
                      dropdownFor="economicCustomerNumber"
                      selectedPreference={economicCustomerPreference}
                      isDisabled={true}
                    />
                  </Col>
                </Row>
              )}
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-1">
            {!economicFilterPreference ||
            !shopifyFilterPreference ||
            !shopifyCustomerPreference ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    {showTooltipText(
                      economicCustomerPreference,
                      shopifyFilterPreference,
                      shopifyCustomerPreference
                    )}
                  </Tooltip>
                }
              >
                <div style={{ cursor: "not-allowed" }}>
                  <Button className="sync-settings-button" disabled>
                    Save changes
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                className="sync-settings-button"
                disabled={isLoading}
                loading={isLoading}
                primary
                onClick={() =>
                  onSaveContactSyncRules({
                    setIsLoading,
                    searchParams,
                    economicFilterPreference,
                    shopifyFilterPreference,
                    shopifyCustomerNumberPreference,
                    shopifyCustomerPreference,
                    dispatch,
                    cmsInstance,
                  })
                }
              >
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncRules;

const showTooltipText = (
  economicFilterPreference,
  shopifyFilterPreference,
  shopifyCustomerPreference
) => {
  if (!economicFilterPreference) {
    return "Please select the search parameter for e-conomic";
  } else if (!shopifyFilterPreference) {
    return "Please select the search parameter for Shopify";
  } else if (!shopifyCustomerPreference) {
    return "Please select the Shopify customer number preference";
  }
};

export const onSaveContactSyncRules = async ({
  setIsLoading,
  searchParams,
  economicFilterPreference,
  shopifyFilterPreference,
  shopifyCustomerNumberPreference,
  shopifyCustomerPreference,
  dispatch,
  cmsInstance,
}) => {
  console.log("triggered");
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");
    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,
      preferences: {
        economicCustomerNumber: {
          direction: shopifyCustomerNumberPreference.value,
          mapping: {
            shProperty: shopifyCustomerPreference,
          },
        },
        filterOptions: {
          filterSHProperty: shopifyFilterPreference,
          filterEcoProperty: economicFilterPreference,
        },
      },
      preferenceType: "customer",
    });
    dispatch(setIsContactSyncRulesAndDefaultMappings({ type: "syncRules" }));

    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.contactSyncRules.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    //toast.error(errorMessage, { className: "react-toast-error" });
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
