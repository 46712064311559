import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { onChangeContactActiveTab } from "../store/slices/preferenceSlice";
import { clearDefaultValues } from "../store/slices/mappingSlice";

import SyncRules from "../components/contacts/SyncRules";
import DefaultMappings from "../components/contacts/DefaultMappings";
import FieldMappings from "../components/contacts/FieldMappings";

const ContactSync = () => {
  const contactTabKeys = Object.freeze({
    syncRules: "syncRules",
    defaultMappings: "defaultMappings",
    fieldMappings: "fieldMappings",
  });

  const dispatch = useDispatch();

  // preference states
  const { contactsActiveTab } = useSelector((state) => state.preference);

  // contact states
  const { isContactsSyncRulesSaved, isContactDefaultMappingsSaved } =
    useSelector((state) => state.contact);

  // mapping states
  const {
    isMappingsSaved: { customer: isCustomerMappingsSaved },
    shopify: { loading: shopifyLoading },
    economic: { loading: economicLoading },
  } = useSelector((state) => state.mapping);

  const {
    ecoDefaultFields: {
      ecoDefaultFields: { paymentTerms, customerGroups },
    },
    paymentTermPreference,
    customerGroupPreference,
  } = useSelector((state) => state.contact);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Tabs
      activeKey={contactsActiveTab}
      onSelect={(selectedKey) => {
        window.scrollTo(0, 0);
        dispatch(clearDefaultValues());
        dispatch(
          onChangeContactActiveTab({
            selectedTab: selectedKey,
            isContactsSyncRulesSaved,
            isContactDefaultMappingsSaved,
            isCustomerMappingsSaved,
            paymentTerms,
            paymentTermPreference,
            customerGroupPreference,
            customerGroups,
            shopifyLoading,
            economicLoading,
          })
        );
      }}
      className="nested-sync-tabs mt-4"
    >
      <Tab eventKey={contactTabKeys.syncRules} title="Customer sync rules">
        <SyncRules />
      </Tab>
      <Tab
        eventKey={contactTabKeys.defaultMappings}
        title="Customer default mappings"
      >
        <DefaultMappings />
      </Tab>
      <Tab
        eventKey={contactTabKeys.fieldMappings}
        title="Customer field mappings"
      >
        <FieldMappings />
      </Tab>
    </Tabs>
  );
};

export default ContactSync;
