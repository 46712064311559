import React, { useEffect, useState } from "react";
import {
  Container,
  Tab,
  Tabs,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  onChangeSettingsActiveTab,
  fetchStatus,
  setShowErrorModal,
} from "../store/slices/preferenceSlice";
import {
  clearDefaultValues,
  fetchShopifyFields,
  fetchEconomicFields,
} from "../store/slices/mappingSlice";
import { fetchEcoDefaultFields } from "../store/slices/contactSlice";
import { fetchStages } from "../store/slices/invoiceSlice";

import { Button, Banner } from "@shopify/polaris";
import { Redirect } from "@shopify/app-bridge/actions";
import createApp from "@shopify/app-bridge";

import ContactSync from "./ContactSync";
import ProductSync from "./ProductSync";
import InvoiceSync from "./InvoiceSync";
import JournalEntries from "../components/journalEntries/JournalEntries";

// CMS Helpers
import { useCmsHook } from "../helpers/cmsHook";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const SyncSettings = () => {
  const tabKeys = Object.freeze({
    contact: "contact",
    invoice: "invoice",
    product: "product",
    journals: "journals",
  });

  const { cmsInstance, setCmsInstance } = useCmsHook();

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // preference states
  const { settingsActiveTab, isEconomicConnected } = useSelector(
    (state) => state.preference
  );

  // contact states
  const { isContactsSyncRulesSaved, isContactDefaultMappingsSaved } =
    useSelector((state) => state.contact);

  // invoice states
  const { isInvoiceSyncRulesSaved } = useSelector((state) => state.invoice);

  // product states
  const { isProductSyncRulesSaved } = useSelector((state) => state.product);

  // journal entries states
  const { isJournalEntriesSaved } = useSelector(
    (state) => state.journalEntries
  );

  // mapping states
  const {
    isMappingsSaved: {
      customer: isCustomerMappingsSaved,
      deal: isInvoiceMappingsSaved,
      productFields: isProductMappingsSaved,
    },
    shopify: { loading: shopifyLoading },
    economic: { loading: economicLoading },
  } = useSelector((state) => state.mapping);

  const {
    subscription: { planData },
  } = useSelector((state) => state.subscription);

  useEffect(() => {
    window.scrollTo(0, 0);
    const shop = searchParams.get("shop");
    if (shop) {
      dispatch(fetchStatus({ shop }));
      dispatch(fetchShopifyFields({ shop }));
      dispatch(fetchStages({ shop }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shop = searchParams.get("shop");
    if (isEconomicConnected && shop) {
      dispatch(fetchEconomicFields({ shop }));
      dispatch(fetchEcoDefaultFields({ shop }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEconomicConnected]);

  useEffect(() => {
    if (window.location.pathname === "/syncSettings") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor();
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToConnect = async () => {
    try {
      setIsLoading(true);
      const config = {
        apiKey: process.env.REACT_APP_SHOPIFY_CLIENT,
        host: new URLSearchParams(window.location.search).get("host"),
      };
      const app = createApp(config);
      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.APP, "/connect");
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      setIsLoading(false);
    }
  };

  const renderTooltip = (content) => (
    <Tooltip style={{ fontSize: "12px" }}>{content}</Tooltip>
  );

  return (
    <div className="page-container">
      {!cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          {window.location.pathname === "/settings" && (
            <div>
              <CmsEditAndSave />
            </div>
          )}
          <Container className="py-5">
            {!isEconomicConnected ? (
              <div className="d-flex flex-row align-items-center justify-content-center pt-4 page-container">
                <Banner title="Your e-conomic account is not connected please reconnect your e-conomic account to proceed further.">
                  <div className="mt-3">
                    <Button
                      plain
                      onClick={() => redirectToConnect()}
                      loading={isLoading}
                    >
                      Connect e-conomic
                    </Button>
                  </div>
                </Banner>
              </div>
            ) : (
              <div className="sync-settings-tab-container py-3 px-4">
                <Tabs
                  activeKey={settingsActiveTab}
                  onSelect={(selectedKey) => {
                    dispatch(clearDefaultValues());
                    dispatch(
                      onChangeSettingsActiveTab({
                        selectedTab: selectedKey,
                        isContactsSyncRulesSaved,
                        isContactDefaultMappingsSaved,
                        isCustomerMappingsSaved,
                        isInvoiceSyncRulesSaved,
                        isInvoiceMappingsSaved,
                        isProductSyncRulesSaved,
                        isProductMappingsSaved,
                        isJournalEntriesSaved,
                        shopifyLoading,
                        economicLoading,
                      })
                    );
                  }}
                  className="sync-settings-tabs"
                >
                  <Tab eventKey={tabKeys.contact} title="Customer">
                    <ContactSync />
                  </Tab>

                  <Tab eventKey={tabKeys.product} title="Products">
                    <ProductSync />
                  </Tab>
                  <Tab eventKey={tabKeys.invoice} title="Invoices">
                    <InvoiceSync />
                  </Tab>
                  {planData.plan && planData.plan === "basic" ? (
                    <Tab
                      title={
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip(
                            "These sync are not available in the Basic plan, please upgrade to the Premium plan"
                          )}
                        >
                          <span>Journal Entries</span>
                        </OverlayTrigger>
                      }
                    ></Tab>
                  ) : (
                    <Tab eventKey={tabKeys.journals} title="Journal Entries">
                      <JournalEntries />
                    </Tab>
                  )}
                </Tabs>
              </div>
            )}
          </Container>
        </>
      )}
    </div>
  );
};

export default SyncSettings;
