import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Checkbox } from "@shopify/polaris";

import { useSelector, useDispatch } from "react-redux";

import {
  fetchJournals,
  fetchAccounts,
  onChangeTextFields,
  setIsJournalEntriesSaved,
  onChangeCreateJournalEntries,
} from "../../store/slices/journalEntriesSlice";

import axios from "axios";
import { BsFillInfoCircleFill } from "react-icons/bs";
import FieldsDropdown from "./FieldsDropdown";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

import { getUrl } from "../../helpers/url";

import { useCmsHook } from "../../helpers/cmsHook";

const JournalEntries = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { cmsInstance } = useCmsHook();

  const {
    journalAccountPreference,
    accountsShopifyPaymentPreference,
    accountsPaymentBankPreference,
    shopifyPaymentsText,
    paymentBankText,
    customerPaymentText,
    createJournalEntries,
    journals: { loading: journalLoading, journals },
    accounts: { loading: accountsLoading, accounts },
  } = useSelector((state) => state.journalEntries);

  useEffect(() => {
    const shop = searchParams.get("shop");
    if (shop) {
      dispatch(fetchJournals({ shop }));
      dispatch(fetchAccounts({ shop }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {journalLoading || accountsLoading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card py-5">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.journalMappings.text"
              )}
            <div className="mt-3">
              <Checkbox
                label="Create Journal Entries"
                checked={createJournalEntries}
                onChange={() => dispatch(onChangeCreateJournalEntries())}
              />
            </div>
            <div>
              {createJournalEntries && (
                <div>
                  <Col className="mt-4">
                    <p className="dropdown-header">
                      Select e-conomic journal account{" "}
                      <span className="required">*</span>{" "}
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip style={{ fontSize: "12px" }}>
                            The e-conomic journal where the journal entries are
                            to be recorded.
                          </Tooltip>
                        }
                      >
                        <span>
                          <BsFillInfoCircleFill />
                        </span>
                      </OverlayTrigger>
                    </p>
                    {journals && journals.length > 0 && (
                      <FieldsDropdown
                        fieldsData={journals}
                        dropdownFor="journalAccount"
                        selectedPreference={journalAccountPreference}
                        setWidth={true}
                      />
                    )}
                  </Col>
                  <Col className="mt-4">
                    <p className="dropdown-header">
                      Select chart of accounts for Shopify Payments
                      <span className="required">*</span>{" "}
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip style={{ fontSize: "12px" }}>
                            The account that is used for payment of Shopify
                            fees.
                          </Tooltip>
                        }
                      >
                        <span>
                          <BsFillInfoCircleFill />
                        </span>
                      </OverlayTrigger>
                    </p>
                    {accounts && accounts.length > 0 && (
                      <FieldsDropdown
                        fieldsData={accounts}
                        dropdownFor="shopifyPayments"
                        selectedPreference={accountsShopifyPaymentPreference}
                        setWidth={true}
                      />
                    )}
                  </Col>
                  <Col className="mt-4">
                    <p className="dropdown-header">
                      Select charts of accounts for payment to bank
                      <span className="required">*</span>{" "}
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip style={{ fontSize: "12px" }}>
                            The account that is used for payments towards the
                            specific store.
                          </Tooltip>
                        }
                      >
                        <span>
                          <BsFillInfoCircleFill />
                        </span>
                      </OverlayTrigger>
                    </p>
                    <FieldsDropdown
                      fieldsData={accounts}
                      dropdownFor="accountsPaymentBank"
                      selectedPreference={accountsPaymentBankPreference}
                      setWidth={true}
                    />
                  </Col>
                </div>
              )}

              <Col className="mt-4">
                <p className="dropdown-header">
                  Text for Shopify Payments{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ fontSize: "12px" }}>
                        The payment text for payments made to Shopify
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                <input
                  type="text"
                  value={shopifyPaymentsText}
                  placeholder="Payments text"
                  onChange={(e) =>
                    dispatch(
                      onChangeTextFields({
                        selectingFor: "shopifyPayments",
                        text: e.target.value,
                      })
                    )
                  }
                  className="journal-text-field mt-2"
                  style={{ width: "35%" }}
                />
              </Col>

              <Col className="mt-4">
                <p className="dropdown-header">
                  Text for payment to bank{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ fontSize: "12px" }}>
                        The payment text for payments made to bank account of
                        the store.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                <input
                  type="text"
                  placeholder="payment to bank"
                  value={paymentBankText}
                  style={{ width: "35%" }}
                  className="journal-text-field mt-2"
                  onChange={(e) =>
                    dispatch(
                      onChangeTextFields({
                        selectingFor: "paymentsBank",
                        text: e.target.value,
                      })
                    )
                  }
                />
              </Col>
              <Col className="mt-4">
                <p className="dropdown-header">
                  Text for customer payment{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ fontSize: "12px" }}>
                        The payment text for payments made by customer
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                <input
                  type="text"
                  placeholder="Customer payment text"
                  value={customerPaymentText}
                  style={{ width: "35%" }}
                  className="journal-text-field mt-2"
                  onChange={(e) =>
                    dispatch(
                      onChangeTextFields({
                        selectingFor: "customerPayment",
                        text: e.target.value,
                      })
                    )
                  }
                />
              </Col>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-2">
            {!journalAccountPreference ||
            !accountsShopifyPaymentPreference ||
            !accountsPaymentBankPreference ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    {showTooltipText({
                      journalAccountPreference,
                      accountsShopifyPaymentPreference,
                      accountsPaymentBankPreference,

                      shopifyPaymentsText,
                      paymentBankText,
                      customerPaymentText,
                    })}
                  </Tooltip>
                }
              >
                <div>
                  <Button disabled>Save changes</Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                primary
                disabled={isLoading}
                loading={isLoading}
                onClick={() =>
                  onSaveJournalMappings({
                    searchParams,
                    dispatch,
                    setIsLoading,
                    journalAccountPreference,
                    accountsShopifyPaymentPreference,
                    accountsPaymentBankPreference,

                    shopifyPaymentsText,
                    paymentBankText,
                    customerPaymentText,
                    createJournalEntries,
                    cmsInstance,
                  })
                }
              >
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default JournalEntries;

export const onSaveJournalMappings = async ({
  searchParams,
  dispatch,
  setIsLoading,
  journalAccountPreference,
  accountsShopifyPaymentPreference,
  accountsPaymentBankPreference,

  shopifyPaymentsText,
  paymentBankText,
  customerPaymentText,
  createJournalEntries,
  cmsInstance,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");

    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,
      preferences: {
        journal: {
          createJournal: {
            journalNumber: {
              name: journalAccountPreference.name,
              value: journalAccountPreference.journalNumber,
            },
            shopifyAccount: {
              name: accountsShopifyPaymentPreference.name,
              value: accountsShopifyPaymentPreference.accountNumber,
            },
            paymentAccount: {
              name: accountsPaymentBankPreference.name,
              value: accountsPaymentBankPreference.accountNumber,
            },

            shopifyAccountText: shopifyPaymentsText,
            paymentAccountText: paymentBankText,
            customerAccountText: customerPaymentText,
          },
          associate: createJournalEntries,
        },
      },
      preferenceType: "journal",
    });
    dispatch(setIsJournalEntriesSaved());
    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.journalEntries.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};

const showTooltipText = ({
  journalAccountPreference,
  accountsShopifyPaymentPreference,
  accountsPaymentBankPreference,
}) => {
  if (!journalAccountPreference) {
    return "Please select the Journal account number.";
  } else if (!accountsShopifyPaymentPreference) {
    return "Please select the account for the Shopify payments";
  } else if (!accountsPaymentBankPreference) {
    return "Please select the account for payments to bank.";
  }
};
