import React from "react";

import { Toast } from "@shopify/polaris";

import { useSelector, useDispatch } from "react-redux";

import { onHideErrorModal } from "../../store/slices/preferenceSlice";

const ErrorToast = () => {
  const dispatch = useDispatch();
  const { errorMessage, showErrorToast } = useSelector(
    (state) => state.preference
  );

  return (
    <>
      {showErrorToast && (
        <Toast
          content={errorMessage}
          onDismiss={() => dispatch(onHideErrorModal())}
          error
        />
      )}
    </>
  );
};

export default ErrorToast;
