import { configureStore } from "@reduxjs/toolkit";

import preferenceSlice from "./slices/preferenceSlice";
import contactSlice from "./slices/contactSlice";
import productSlice from "./slices/productSlice";
import invoiceSlice from "./slices/invoiceSlice";
import mappingSlice from "./slices/mappingSlice";
import subscriptionSlice from "./slices/subscriptionSlice";
import journalEntriesSlice from "./slices/journalEntriesSlice";

export const store = configureStore({
  reducer: {
    preference: preferenceSlice,
    contact: contactSlice,
    product: productSlice,
    invoice: invoiceSlice,
    mapping: mappingSlice,
    subscription: subscriptionSlice,
    journalEntries: journalEntriesSlice,
  },
});
