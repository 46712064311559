import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

const initialState = {
  settingsActiveTab: "contact",
  contactsActiveTab: "syncRules",
  productActiveTab: "syncRules",
  invoiceActiveTab: "syncRules",
  isEconomicConnected: null,
  saveModalPreference: false,
  selectedTabValues: {},
  data: {
    loading: false,
    error: "",
    status: {},
  },
  errorMessage: "",
  successMessage: "",
  showSuccessToast: false,
  showErrorToast: false,
};

export const fetchStatus = createAsyncThunk(
  "preferences/fetchStatus",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_STATUS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const preferenceSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    onChangeSettingsActiveTab(state, { payload }) {
      if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.settingsActiveTab === "contact" &&
        (payload.selectedTab === "invoice" ||
          payload.selectedTab === "product" ||
          payload.selectedTab === "journals") &&
        (!payload.isContactsSyncRulesSaved ||
          !payload.isContactDefaultMappingsSaved ||
          !payload.isCustomerMappingsSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.settingsActiveTab === "invoice" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "product" ||
          payload.selectedTab === "journals") &&
        (!payload.isInvoiceSyncRulesSaved || !payload.isInvoiceMappingsSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.settingsActiveTab === "product" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "invoice" ||
          payload.selectedTab === "journals") &&
        (!payload.isProductMappingsSaved || !payload.isProductSyncRulesSaved)
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.settingsActiveTab === "journals" &&
        (payload.selectedTab === "contact" ||
          payload.selectedTab === "invoice" ||
          payload.selectedTab === "product") &&
        !payload.isJournalEntriesSaved
      ) {
        state.selectedTabValues = {
          tab: payload.selectedTab,
          subTab: "syncRules",
        };
        state.saveModalPreference = true;
      } else {
        state.settingsActiveTab = payload.selectedTab;
      }
    },

    onChangeContactActiveTab(state, { payload }) {
      if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.contactsActiveTab === "syncRules" &&
        !payload.isContactsSyncRulesSaved &&
        (payload.selectedTab === "defaultMappings" ||
          payload.selectedTab === "fieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.contactsActiveTab === "defaultMappings" &&
        !payload.isContactDefaultMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "fieldMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };

        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.contactsActiveTab === "fieldMappings" &&
        !payload.isCustomerMappingsSaved &&
        (payload.selectedTab === "syncRules" ||
          payload.selectedTab === "defaultMappings")
      ) {
        state.selectedTabValues = {
          tab: "contact",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        if (
          ((payload.paymentTerms && payload.paymentTerms.length <= 0) ||
            payload.customerGroups.length <= 0) &&
          (!payload.paymentTermPreference || !payload.customerGroupPreference)
        ) {
          state.contactsActiveTab = "defaultMappings";
        } else {
          state.contactsActiveTab = payload.selectedTab;
        }
      }
    },

    onChangeProductActiveTab(state, { payload }) {
      if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.productActiveTab === "syncRules" &&
        !payload.isProductSyncRulesSaved &&
        payload.selectedTab === "fieldMappings"
      ) {
        state.selectedTabValues = {
          tab: "product",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.shopifyLoading &&
        state.productActiveTab === "fieldMappings" &&
        !payload.isProductMappingsSaved &&
        payload.selectedTab === "syncRules"
      ) {
        state.selectedTabValues = {
          tab: "product",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.productActiveTab = payload.selectedTab;
      }
    },

    onChangeInvoiceActiveTab(state, { payload }) {
      if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.invoiceActiveTab === "syncRules" &&
        !payload.isInvoiceSyncRulesSaved &&
        payload.selectedTab === "fieldMappings"
      ) {
        state.selectedTabValues = {
          tab: "invoice",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else if (
        state.isEconomicConnected &&
        !payload.shopifyLoading &&
        !payload.economicLoading &&
        state.invoiceActiveTab === "fieldMappings" &&
        !payload.isInvoiceMappingsSaved &&
        payload.selectedTab === "syncRules"
      ) {
        state.selectedTabValues = {
          tab: "invoice",
          subTab: payload.selectedTab,
        };
        state.saveModalPreference = true;
      } else {
        state.invoiceActiveTab = payload.selectedTab;
      }
    },

    onChangeEconomicConnected(state, { payload }) {
      state.isEconomicConnected = payload.isConnected;
    },

    onChangeSaveModalPreference(state, { payload }) {
      state.saveModalPreference = payload.show;
    },

    onChangeTabValues(state) {
      window.scroll(0, 0);
      state.settingsActiveTab = state.selectedTabValues.tab;
      if (state.selectedTabValues.tab === "contact") {
        state.contactsActiveTab = state.selectedTabValues.subTab;
      } else if (state.selectedTabValues.tab === "product") {
        state.productActiveTab = state.selectedTabValues.subTab;
      } else {
        state.invoiceActiveTab = state.selectedTabValues.subTab;
      }
    },

    setShowSuccessModal(state, action) {
      state.successMessage = action.payload.message;
      state.showSuccessToast = true;
    },
    onHideSuccessModal(state) {
      state.successMessage = "";
      state.showSuccessToast = false;
    },
    setShowErrorModal(state, action) {
      state.errorMessage = action.payload.message;
      state.showErrorToast = true;
    },
    onHideErrorModal(state) {
      state.errorMessage = "";
      state.showErrorToast = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.pending, (state, action) => {
      if (!state.data.loading) {
        state.data.loading = true;
      }
    });
    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      if (state.data.loading) {
        state.data.loading = false;
        state.data.status = action.payload;
        state.data.error = "";

        const { creds } = action.payload;
        if (creds?.economic) state.isEconomicConnected = true;
      }
    });
    builder.addCase(fetchStatus.rejected, (state, action) => {
      if (state.data.loading) {
        state.data.loading = false;
        state.data.status = {};
        state.data.error = action.error.message ?? "";
      }
    });
  },
});

export const {
  onChangeSettingsActiveTab,
  onChangeContactActiveTab,
  onChangeProductActiveTab,
  onChangeInvoiceActiveTab,
  onChangeEconomicConnected,
  onChangeSaveModalPreference,
  onChangeTabValues,
  onHideErrorModal,
  onHideSuccessModal,
  setShowErrorModal,
  setShowSuccessModal,
} = preferenceSlice.actions;

export default preferenceSlice.reducer;
