import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Spinner, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { Button } from "@shopify/polaris";

import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { BsFillInfoCircleFill } from "react-icons/bs";

import FieldsDropdown from "../FieldsDropdown";

import { setIsContactSyncRulesAndDefaultMappings } from "../../store/slices/contactSlice";
import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

import { getUrl } from "../../helpers/url";

import { useCmsHook } from "../../helpers/cmsHook";

const DefaultMappings = () => {
  const { cmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [missingDefaultFields, setMissingDefaultFields] = useState({
    status: false,
    message: "",
  });

  const {
    paymentTermPreference,
    currencyPreference,
    layoutPreference,
    customerGroupPreference,
    vatZonePreference,
    ecoDefaultFields: {
      loading,
      ecoDefaultFields: {
        paymentTerms,
        customerGroups,
        vatZones,
        currencies,
        layouts,
      },
    },
  } = useSelector((state) => state.contact);
  const checkingDefaultFields = () => {
    if (
      paymentTerms &&
      paymentTerms?.length === 0 &&
      customerGroups &&
      customerGroups?.length === 0
    ) {
      dispatch(
        setShowErrorModal({
          message:
            "Payment terms and customer groups are missing in the connected e-conomic account.",
        })
      );
      setMissingDefaultFields({
        status: true,
        message:
          "Payment terms and customer groups are missing in the connected e-conomic account.",
      });
    } else if (paymentTerms && paymentTerms?.length === 0) {
      dispatch(
        setShowErrorModal({
          message:
            "Payment terms are missing in the connected e-conomic account.",
        })
      );
      setMissingDefaultFields({
        status: true,
        message:
          "Payment terms are missing in the connected e-conomic account.",
      });
    } else if (customerGroups && customerGroups?.length === 0) {
      dispatch(
        setShowErrorModal({
          message:
            "customer groups are missing in the connected e-conomic account.",
        })
      );
      setMissingDefaultFields({
        status: true,
        message:
          "customer groups are missing in the connected e-conomic account.",
      });
    } else if (vatZones && vatZones?.length === 0) {
      dispatch(
        setShowErrorModal({
          message:
            "customer groups are missing in the connected e-conomic account.",
        })
      );
      setMissingDefaultFields({
        status: true,
        message:
          "customer groups are missing in the connected e-conomic account.",
      });
    } else if (currencies && currencies?.length === 0) {
      dispatch(
        setShowErrorModal({
          message:
            "currencies  are missing in the connected e-conomic account.",
        })
      );
      setMissingDefaultFields({
        status: true,
        message: "currencies  are missing in the connected e-conomic account.",
      });
    } else if (layouts && layouts?.length === 0) {
      dispatch(
        setShowErrorModal({
          message: "Layouts  are missing in the connected e-conomic account.",
        })
      );
      setMissingDefaultFields({
        status: true,
        message: "Layouts  are missing in the connected e-conomic account.",
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      checkingDefaultFields();
    }, 2000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTerms, customerGroups, vatZones, currencies, layouts]);

  return (
    <div>
      {loading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card py-5">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.contact.defaultMappings"
              )}

            {missingDefaultFields.status && (
              <Alert variant="danger">
                <Alert.Heading>
                  Please configure the default settings of your e-conomic
                  account.
                </Alert.Heading>
                <p>{missingDefaultFields?.message}</p>
              </Alert>
            )}
            <div>
              <Col
                md={9}
                lg={7}
                className="d-flex flex-row justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Payment terms<span className="required">*</span>{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip style={{ fontSize: "12px" }}>
                        Define the standard terms you offer to your customers
                        for invoice payment
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {paymentTerms && paymentTerms.length >= 0 && (
                  <FieldsDropdown
                    fieldsData={paymentTerms}
                    dropdownFor="paymentTerms"
                    selectedPreference={paymentTermPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Currency<span className="required">*</span>{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Set the default currency in which the transactions will
                        be carried out.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {currencies && currencies.length >= 0 && (
                  <FieldsDropdown
                    fieldsData={currencies}
                    dropdownFor="currency"
                    selectedPreference={currencyPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Invoice layout<span className="required">*</span>{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Choose the standard design or template for the invoices
                        that will be generated.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {layouts && layouts.length >= 0 && (
                  <FieldsDropdown
                    fieldsData={layouts}
                    dropdownFor="layout"
                    selectedPreference={layoutPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  Customer group<span className="required">*</span>{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Select the default group that new customers will be
                        assigned to in e-conomic.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {customerGroups && customerGroups.length >= 0 && (
                  <FieldsDropdown
                    fieldsData={customerGroups}
                    dropdownFor="customerGroup"
                    selectedPreference={customerGroupPreference}
                    setWidth={true}
                  />
                )}
              </Col>
              <Col
                md={8}
                lg={7}
                className="d-flex justify-content-between align-items-center mt-3"
              >
                <p className="dropdown-header">
                  VAT zone<span className="required">*</span>{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Specify the default VAT zone applicable to your
                        customers.
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsFillInfoCircleFill />
                    </span>
                  </OverlayTrigger>
                </p>
                {vatZones && vatZones.length >= 0 && (
                  <FieldsDropdown
                    fieldsData={vatZones}
                    dropdownFor="vatZone"
                    selectedPreference={vatZonePreference}
                    setWidth={true}
                  />
                )}
              </Col>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-2">
            {!paymentTermPreference ||
            !currencyPreference ||
            !vatZonePreference ||
            !layoutPreference ||
            !customerGroupPreference ||
            (paymentTerms && paymentTerms.length <= 0) ||
            (customerGroups && customerGroups.length <= 0) ||
            (layouts && layouts.length <= 0) ||
            (currencies && currencies.length <= 0) ||
            (vatZones && vatZones.length <= 0) ||
            (paymentTerms &&
              paymentTerms.length > 0 &&
              !paymentTerms
                .map((item) => item?.value)
                .includes(paymentTermPreference.value)) ||
            (customerGroups &&
              customerGroups.length > 0 &&
              !customerGroups
                .map((item) => item?.value)
                .includes(customerGroupPreference.value)) ||
            (layouts &&
              layouts.length > 0 &&
              !layouts
                .map((item) => item?.value)
                .includes(layoutPreference.value)) ||
            (currencies &&
              currencies.length > 0 &&
              !currencies
                .map((item) => item?.value)
                .includes(currencyPreference.value)) ||
            (vatZones &&
              vatZones.length > 0 &&
              !vatZones
                .map((item) => item?.value)
                .includes(vatZonePreference.value)) ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    {showTooltipText(
                      paymentTermPreference,
                      currencyPreference,
                      layoutPreference,
                      customerGroupPreference,
                      vatZonePreference,
                      paymentTerms,
                      customerGroups,
                      vatZones,
                      currencies,
                      layouts
                    )}
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    style={{ cursor: "not-allowed" }}
                    className="sync-settings-button"
                    disabled
                  >
                    Save changes
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                className="sync-settings-button"
                primary
                onClick={() =>
                  onSaveDefaultMappings({
                    setIsLoading,
                    searchParams,
                    paymentTermPreference,
                    currencyPreference,
                    layoutPreference,
                    customerGroupPreference,
                    vatZonePreference,
                    dispatch,
                    cmsInstance,
                  })
                }
                disabled={isLoading}
                loading={isLoading}
              >
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultMappings;

const showTooltipText = (
  paymentTermPreference,
  currencyPreference,
  layoutPreference,
  customerGroupPreference,
  vatZonePreference,
  paymentTerms,
  customerGroups,
  vatZones,
  layouts,
  currencies
) => {
  if (!paymentTermPreference) {
    return "Please select the Payment Terms";
  } else if (!currencyPreference) {
    return "Please select the Currency";
  } else if (!layoutPreference) {
    return "Please select the layout";
  } else if (!customerGroupPreference) {
    return "Please select the customer Group";
  } else if (!vatZonePreference) {
    return "Please select the VAT Zone";
  } else if (
    paymentTerms.length <= 0 ||
    customerGroups.length <= 0 ||
    vatZones.length <= 0 ||
    layouts.length <= 0 ||
    currencies.length <= 0
  ) {
    return "Please select all the default fields as these are required fields";
  } else if (
    paymentTerms &&
    paymentTerms.length > 0 &&
    !paymentTerms
      .map((item) => item?.value)
      .includes(paymentTermPreference.value)
  ) {
    return "The selected payment term is deleted from your connected e-conomic account please remap the field";
  } else if (
    customerGroups &&
    customerGroups.length > 0 &&
    !customerGroups
      .map((item) => item?.value)
      .includes(customerGroupPreference.value)
  ) {
    return "The selected customer group is deleted from your connected e-conomic account please remap the field";
  } else if (
    layouts &&
    layouts.length > 0 &&
    !layouts.map((item) => item?.value).includes(layoutPreference.value)
  ) {
    return "The selected layout is deleted from your connected e-conomic account please remap the field";
  } else if (
    currencies &&
    currencies.length > 0 &&
    !currencies.map((item) => item?.value).includes(currencyPreference.value)
  ) {
    return "The selected currency is deleted from your connected e-conomic account please remap the field";
  } else if (
    vatZones &&
    vatZones.length > 0 &&
    !vatZones.map((item) => item?.value).includes(vatZonePreference.value)
  ) {
    return "The selected vat zone is deleted from your connected e-conomic account please remap the field";
  }
};

export const onSaveDefaultMappings = async ({
  setIsLoading,
  searchParams,
  paymentTermPreference,
  currencyPreference,
  layoutPreference,
  customerGroupPreference,
  vatZonePreference,
  dispatch,
  cmsInstance,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");

    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,
      preferences: {
        economicFields: {
          paymentTerms: paymentTermPreference,
          customerGroups: customerGroupPreference,
          layouts: layoutPreference,
          vatZones: vatZonePreference,
          currencies: currencyPreference,
        },
      },
      preferenceType: "customer",
    });
    dispatch(
      setIsContactSyncRulesAndDefaultMappings({ type: "defaultMappings" })
    );
    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.contactDefaultMappings.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
