import React, { useState } from "react";
import { Dropdown, Form, Stack, Badge, CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GoSearch } from "react-icons/go";

import { onAddShFieldsToCustomMappings } from "../../store/slices/mappingSlice";

const CustomProductMappingDropdown = ({
  mappedItem,
  mappings,
  shopifyDropdownFields,
  mappingsFor,
  deletedShopifyFields,
}) => {
  const dispatch = useDispatch();

  // mapping states
  const {
    mappings: {
      defaultMappings: { products: productMappings },
    },
  } = useSelector((state) => state.mapping);

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const getMappedSHFields = () => {
    if (mappedItem.SH_Fields && mappedItem.SH_Fields.length > 0) {
      return mappedItem.SH_Fields.map((shField) => shField.SH_ProductId);
    } else {
      return [];
    }
  };

  const searchFields = (enteredInput) => {
    setSearchInput(enteredInput);
    const searchArray = shopifyDropdownFields.filter((fieldItem) => {
      return fieldItem.HRF.toLowerCase().includes(enteredInput.toLowerCase());
    });
    setSearchResults(searchArray);
  };

  const disableCheckedFields = (fieldItem) => {
    const mappedShFields = productMappings?.flatMap((mapping) =>
      mapping.SH_Fields.map((shField) => shField.SH_ProductId)
    );

    const isFound = mappedShFields.includes(fieldItem);
    if (isFound) {
      return !mappedItem.SH_Fields.map(
        (shField) => shField.SH_ProductId
      ).includes(fieldItem);
    }

    return isFound;
  };

  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle className="custom-product-mapping-dropdown">
        {mappedItem?.SH_Fields && mappedItem?.SH_Fields.length > 0 ? (
          <Stack direction="horizontal" gap={2} style={{ flexWrap: "wrap" }}>
            {mappedItem.SH_Fields.map((shField) => (
              <Badge
                key={shField.SH_FieldName_CRF}
                className="d-flex flex-row justify-content-between"
              >
                <span className="me-2">
                  {shField.SH_FieldName_HRF}
                  {deletedShopifyFields.includes(shField.SH_FieldName_CRF) && (
                    <span className="error-text">(deleted)</span>
                  )}
                </span>
                <CloseButton
                  style={{ fontSize: "8px" }}
                  className="close-btn"
                  onClick={() =>
                    dispatch(
                      onAddShFieldsToCustomMappings({
                        checked: false,
                        mappingsFor,
                        mappedItem,
                        selectedValue: {
                          HRF: shField.SH_FieldName_HRF,
                          CRF: shField.SH_FieldName_CRF,
                          type: shField.SH_FieldType,
                          description: shField.SH_Field_Description,
                          productId: shField.SH_ProductId,
                        },
                      })
                    )
                  }
                />
              </Badge>
            ))}
          </Stack>
        ) : (
          <span style={{ fontSize: "14px" }}>Not Mapped</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu renderOnMount={true} popperConfig={{ strategy: "fixed" }}>
        <div className="p-2">
          <div className="dropdown-search-container px-2">
            <span>
              <GoSearch size={20} className="me-2" />
            </span>
            <input
              type="search"
              placeholder="search"
              className="search-input"
              onChange={(e) => searchFields(e.target.value)}
            />
          </div>
          <hr />
          <div className="dropdown-menu-container">
            {(searchInput.length > 0
              ? searchResults
              : shopifyDropdownFields
            ).map((fieldItem, index) => (
              <div
                key={index}
                className="px-3 py-2 custom-product-dropdown-item"
              >
                <Form.Check
                  type="checkbox"
                  id={`${fieldItem.productId}-${mappedItem.id}`}
                  label={fieldItem.HRF}
                  style={{ fontSize: "14px" }}
                  name="shopifyDropdownFields"
                  value={fieldItem.CRF}
                  disabled={disableCheckedFields(fieldItem.productId)}
                  checked={getMappedSHFields().includes(fieldItem.productId)}
                  onChange={(e) => {
                    dispatch(
                      onAddShFieldsToCustomMappings({
                        checked: e.target.checked,
                        mappingsFor,
                        mappedItem,
                        selectedValue: fieldItem,
                      })
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomProductMappingDropdown;
