import React from "react";
import { Spinner } from "react-bootstrap";

import { useSelector } from "react-redux";

import MappingWindow from "../mapping/MappingWindow";

const FieldMappings = () => {
  const {
    mappings: {
      defaultMappings: { productFields: mappings },
    },
    shopify: {
      shopifyDropdownFields: { productFieldMapping: shopifyFields },
      loading: shopifyLoading,
    },
    economic: {
      economicDropdownFields: { productFieldMappings: ecoFields },
      loading: ecoLoading,
    },
  } = useSelector((state) => state.mapping);

  return (
    <div>
      {shopifyLoading || ecoLoading ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div>
          {shopifyFields &&
            shopifyFields.length > 0 &&
            ecoFields &&
            ecoFields.length > 0 && (
              <MappingWindow
                mappings={mappings}
                ecoFields={ecoFields}
                shopifyFields={shopifyFields}
                mappingsFor="productFields"
                notificationPath="productFieldMappings"
                cmsPath="cmsContent.syncSettings.product.fieldMappings"
              />
            )}
        </div>
      )}
    </div>
  );
};

export default FieldMappings;
