import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { getUrl } from "../../helpers/url";
import { updatePlanData } from "../../store/slices/subscriptionSlice";

import {
  setShowSuccessModal,
  setShowErrorModal,
} from "../../store/slices/preferenceSlice";

import { Button, ButtonGroup } from "@shopify/polaris";

function PostpaidBillingCards({ cmsInstance }) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  // states
  const {
    cardsData,
    subscription: { planData },
    plansList,
  } = useSelector((state) => state.subscription);

  /**
   * @description This function is responsible for opening new window for adding card in stripe.
   */
  const openNewWindow = () => {
    window.addEventListener("message", (e) => {
      if (e.data?.paymentStatus && e.data?.plan) {
        console.log(e.data?.paymentStatus);
        setPaymentStatus(e.data?.paymentStatus);
        setSelectedPlan(e.data?.plan);
      }
    });
  };

  const onUpdateSubscription = async ({ plan }) => {
    try {
      setSelectedPlan(plan);
      setIsUpdating(true);
      const shop = searchParams.get("shop");

      // Create Checkout
      const { data: res } = await axios.post(
        getUrl("REACT_APP_CREATE_SUBSCRIPTION"),
        {
          shop: shop,
          appSlug: "shopifyEconomic",
          returnUrl: `${window.location.origin}/redirect?shop=${shop}&paymentStatus=cardAdded&plan=${plan}`,
          plan: plan,
        }
      );

      if (res) {
        // redirects to the stripe window
        window.open(res, "_blank");
      }
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      setIsUpdating(false);
      setSelectedPlan("");
    }
  };

  /**
   * This function is used for cancelling the subscription
   */
  const onCancelSubscription = async ({ plan }) => {
    try {
      setSelectedPlan(plan);
      setIsDowngrading(true);
      const shop = searchParams.get("shop");
      await axios.post(getUrl("REACT_APP_CANCEL_SUBSCRIPTION"), {
        shop,
        plan,
      });
      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance &&
            cmsInstance.sendData().cmsContent.notifications.subscription
              .successContent.cancelSubscription.message,
        })
      );
      //dispatch(fetchStatus({ shop }));
      dispatch(
        updatePlanData({ plan: `${plan}-cancelled`, subscriptionId: false })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      //toast.error(errorMessage, { className: "react-toast-error" });
      dispatch(setShowErrorModal({ message: errorMessage }));
    } finally {
      setIsDowngrading(false);
      setSelectedPlan("");
    }
  };

  /**
   * @description This function is used for checking payment status wh
   */
  const paymentStatusCheck = async () => {
    const shop = searchParams.get("shop");
    if (paymentStatus === "cardAdded" && selectedPlan) {
      dispatch(updatePlanData({ plan: selectedPlan, subscriptionId: true }));
      navigate(`..${window.location.pathname}?shop=${shop}&paymentStatus=none`);
      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance.sendData().cmsContent.notifications.subscription
              .successContent.upgradePlan.message,
        })
      );
    } else if (paymentStatus === "failed") {
      dispatch(
        setShowErrorModal({
          message:
            cmsInstance.sendData().cmsContent.notifications.subscription
              .failContent.paymentFailed.message,
        })
      );
    }
    setPaymentStatus("");
  };

  const renderButtons = (card, isUpdating, isDowngrading) => {
    if (
      (planData.plan === "trial" || planData.plan === "free trial") &&
      card.name === "Free Trial"
    ) {
      return (
        <div className="mt-2">
          {planData.usageCount >= 15 ? (
            <Button className="pricing-selected-btn" disabled fullWidth>
              Trial Expired
            </Button>
          ) : (
            <Button className="pricing-selected-btn" disabled fullWidth>
              Selected
            </Button>
          )}
        </div>
      );
    } else if (
      (planData.plan === "trial" || planData.plan === "premium") &&
      card.name === "Basic"
    ) {
      return (
        <div className="mt-2">
          <Button
            disabled={isUpdating}
            onClick={() => onUpdateSubscription({ plan: "basic" })}
            fullWidth
            loading={isUpdating && selectedPlan === "basic"}
            primary
          >
            {planData.plan === "trial" ? "Upgrade" : "Downgrade"}
          </Button>
        </div>
      );
    } else if (
      (planData.plan === "trial" || planData.plan === "basic") &&
      card.name === "Premium"
    ) {
      return (
        <div className="mt-2">
          <Button
            disabled={isUpdating}
            onClick={() => onUpdateSubscription({ plan: "premium" })}
            fullWidth
            primary
            loading={isUpdating && selectedPlan === "premium"}
          >
            Upgrade
          </Button>
        </div>
      );
    } else if (planData.plan === "basic" && card.name === "Basic") {
      return (
        <ButtonGroup fullWidth>
          <div className="mt-2">
            <div>
              <Button disabled>Selected</Button>
            </div>
            <div className="mt-2">
              <Button
                disabled={isDowngrading}
                onClick={() => onCancelSubscription({ plan: "basic" })}
                loading={isDowngrading && selectedPlan === "basic"}
              >
                Cancel Subscription
              </Button>
            </div>
          </div>
        </ButtonGroup>
      );
    } else if (planData.plan === "premium" && card.name === "Premium") {
      return (
        <ButtonGroup fullWidth>
          <div className="mt-2">
            <div>
              <Button disabled>Selected</Button>
            </div>
            <div className="mt-2">
              <Button
                disabled={isDowngrading}
                onClick={() => onCancelSubscription({ plan: "premium" })}
                loading={isDowngrading && selectedPlan === "premium"}
              >
                Cancel Subscription
              </Button>
            </div>
          </div>
        </ButtonGroup>
      );
    } else if (
      (planData.plan === "premium-cancelled" ||
        planData.plan === "basic-cancelled") &&
      card.name === "Premium"
    ) {
      return (
        <div className="mt-2">
          <Button
            disabled={isUpdating}
            onClick={() => onUpdateSubscription({ plan: "premium" })}
            primary
            loading={isUpdating && selectedPlan === "premium"}
            fullWidth
          >
            Upgrade
          </Button>
        </div>
      );
    } else if (
      (planData.plan === "premium-cancelled" ||
        planData.plan === "basic-cancelled") &&
      card.name === "Basic"
    ) {
      return (
        <div className="mt-2">
          <Button
            disabled={isUpdating}
            onClick={() => onUpdateSubscription({ plan: "basic" })}
            primary
            loading={isUpdating && selectedPlan === "basic"}
            fullWidth
          >
            Upgrade
          </Button>
        </div>
      );
    }
  };

  /**
   * This function is used for conditionally rendering the text
   */
  const renderText = () => {
    if (planData.plan === "trial") {
      if (planData.usageCount >= 15) {
        return "The trial period of this app has expired. To continue using it, please upgrade to the premium plan.";
      } else {
        return "You are currently subscribed to the Trial Plan.";
      }
    } else if (planData.plan === "basic") {
      return "You are currently subscribed to the Basic plan.";
    } else if (planData.plan === "basic-cancelled") {
      return "Your Basic plan has been cancelled. Please upgrade to Premium or Basic to continue using the app";
    } else if (planData.plan === "premium") {
      return "You are currently subscribed to the Premium plan.";
    } else if (planData.plan === "premium-cancelled") {
      return "Your Premium plan has been cancelled. Please upgrade to Premium to continue using the app";
    }
  };

  useEffect(() => {
    openNewWindow();
    if (paymentStatus) {
      paymentStatusCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  return (
    <div>
      {window.location.pathname !== "/setup" && (
        <Alert variant="light" style={{ border: "1px solid #dcdcdc" }}>
          <div className="d-flex flex-row justify-content-left">
            <p>{renderText()}</p>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="mb-0">
              Sync Usage:{" "}
              <b>
                {planData.plan === "trial" && planData.usageCount >= 15
                  ? 15
                  : planData.usageCount}
              </b>
            </p>
          </div>
        </Alert>
      )}

      <Row
        className={`pricing-cards-container ${
          plansList.includes(planData.plan) &&
          "d-flex flex-row justify-content-around"
        }`}
      >
        {cardsData &&
          cardsData.map((card) => (
            <div
              key={card.id}
              className={`pricing-card px-3 py-3 my-3 ${
                plansList.includes(planData.plan) &&
                card.name === "Free Trial" &&
                "d-none"
              }`}
              style={{
                maxWidth: `${plansList.includes(planData.plan) && "350px"}`,
              }}
            >
              <div className="text-center">
                {cmsInstance &&
                  cmsInstance.showRichText(
                    `cmsContent.subscription.${card.id}.heading`,
                    "mb-1"
                  )}
                {cmsInstance &&
                  cmsInstance.showRichText(
                    `cmsContent.subscription.${card.id}.description`
                  )}
                {cmsInstance &&
                  cmsInstance.showRichText(
                    `cmsContent.subscription.${card.id}.price`
                  )}
              </div>

              <div className="my-3">
                {renderButtons(card, isUpdating, isDowngrading)}
              </div>

              {cmsInstance &&
                cmsInstance.showRichText(
                  `cmsContent.subscription.${card.id}.points`
                )}
            </div>
          ))}
      </Row>
    </div>
  );
}

export default PostpaidBillingCards;
