import { useState, useEffect } from "react";
import { Container, Col, Card, Spinner } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Button, ButtonGroup } from "@shopify/polaris";

import axios from "axios";

import shopifyLogo from "../assets/images/shopify-logo.svg";
import economicLogo from "../assets/images/economic-logo.svg";

import {
  fetchStatus,
  onChangeEconomicConnected,
  setShowErrorModal,
  setShowSuccessModal,
} from "../store/slices/preferenceSlice";

import { getUrl } from "../helpers/url";

import { useCmsHook } from "../helpers/cmsHook";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const ConnectEconomic = () => {
  const { cmsInstance, setCmsInstance } = useCmsHook();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [disconnectLoading, setDisConnectLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [connectLoading, setIsConnectLoading] = useState(false);
  const [token, setToken] = useState("");

  const { isEconomicConnected } = useSelector((state) => state.preference);

  const connectEconomicFromInstall = () => {
    const shop = searchParams.get("shop");
    const url = `https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=${process.env.REACT_APP_ECO_PUBLIC_TOKEN}&redirectUrl=${window.location.origin}/redirect?shop=${shop}`;
    const params = `toolbar=no, menubar=no, width=1000, height=700, top=100, left=250`;
    const ref = window.open(url, "connectEconomic", params);
    ref.opener = null;
    const reference = setInterval(() => {
      const queryString = ref.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("token");
      if (token) {
        setToken(token);
        clearInterval(reference);
        ref.close();
      }
    }, 1000);
  };

  const disConnectEconomic = async () => {
    try {
      setDisConnectLoading(true);
      const shop = searchParams.get("shop");
      await axios.post(getUrl("REACT_APP_DISCONNECT_ECONOMIC"), {
        shop,
      });
      dispatch(onChangeEconomicConnected({ isConnected: false }));
      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance &&
            cmsInstance.sendData().cmsContent.notifications.authentication
              .successContent.disconnectEconomic.message,
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setDisConnectLoading(false);
    }
  };

  const storeEconomicToken = async (shop, token) => {
    try {
      setIsLoading(true);
      await axios.post(getUrl("REACT_APP_CONNECT_ECONOMIC"), {
        ecoGrantToken: token,
        shop,
      });
      dispatch(onChangeEconomicConnected({ isConnected: true }));
      dispatch(
        setShowSuccessModal({
          message:
            cmsInstance &&
            cmsInstance.sendData().cmsContent.notifications.authentication
              .successContent.connectEconomic.message,
        })
      );
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setIsLoading(false);
      setIsConnectLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const shop = searchParams.get("shop");
    const token = searchParams.get("token");
    const session = searchParams.get("session");

    if (shop && session) {
      dispatch(fetchStatus({ shop }));
    }
    if (token && shop && session) {
      storeEconomicToken(shop, token);
      dispatch(
        setShowSuccessModal({
          message: "e-conomic has been connected successfully",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      const shop = searchParams.get("shop");
      if (shop) {
        dispatch(fetchStatus({ shop }));
      }
      if (token && shop) {
        storeEconomicToken(shop, token);
        setToken("");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (window.location.pathname === "/connect-economic") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor(dispatch);
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container">
      {isLoading || !cmsInstance ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          {window.location.pathname === "/connect-economic" && (
            <div>
              <CmsEditAndSave />
            </div>
          )}
          <Container
            className="d-flex flex-row justify-content-center align-items-center"
            style={{
              minHeight: `${
                window.location.pathname === "/setup" ? "90vh" : "100vh"
              }`,
            }}
          >
            <Col lg={7}>
              <Card>
                <div className="w-100 authentication-card d-flex flex-column align-items-center">
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.heading",
                      "my-4"
                    )}
                  <div className="d-flex flex-row align-items-center justify-content-center my-4">
                    <div className="logo-container">
                      <Card.Img
                        src={shopifyLogo}
                        alt="shopify-logo"
                        className="logo"
                      />
                    </div>
                    <div>
                      <AiOutlinePlus size={17} className="plus-icon mx-3" />
                    </div>
                    <div className="logo-container">
                      <Card.Img
                        src={economicLogo}
                        alt="economic-logo"
                        className="logo"
                      />
                    </div>
                  </div>

                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description1",
                      "w-50 authentication-para"
                    )}

                  {isEconomicConnected &&
                    cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description2.connected",
                      "text-center w-50"
                    )}
                  {!isEconomicConnected &&
                    cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description2.disconnected",
                      "text-center w-50 my-2"
                    )}
                  <ButtonGroup>
                    <div className="d-flex my-3">
                      <div className="me-2">
                        <Button
                          onClick={() => {
                            connectEconomicFromInstall();
                          }}
                          className="me-2 connect-button"
                          disabled={isEconomicConnected}
                          loading={connectLoading}
                          primary
                        >
                          <span>
                            {isEconomicConnected
                              ? "e-conomic connected"
                              : "Connect e-conomic"}
                          </span>
                        </Button>
                      </div>
                      {isEconomicConnected && (
                        <Button
                          onClick={disConnectEconomic}
                          className="disconnect-button"
                          loading={disconnectLoading}
                          destructive
                        >
                          Disconnect e-conomic
                        </Button>
                      )}
                    </div>
                  </ButtonGroup>

                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.authentication.description3",
                      "text-center w-50"
                    )}
                  <p className="w-50 text-center mt-3">
                    Here's the{" "}
                    <a href="/setup-guide" target="_blank">
                      link
                    </a>{" "}
                    to the setup guide
                  </p>
                </div>
              </Card>
            </Col>
          </Container>
        </>
      )}
    </div>
  );
};

export default ConnectEconomic;
