import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { GoSearch } from "react-icons/go";

import { onChangeJournalDropdownAndFields } from "../../store/slices/journalEntriesSlice";

const FieldsDropdown = ({
  fieldsData,
  dropdownFor,
  selectedPreference,
  selectedPipelines,
  setWidth,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const dispatch = useDispatch();
  const onSearchFields = (searchValue) => {
    setSearchInput(searchValue);

    const searchArray = fieldsData?.filter((fieldItem) =>
      fieldItem.name.toLowerCase().includes(searchInput.toLocaleLowerCase())
    );
    setSearchResults(searchArray);
  };

  const findSyncFieldsTitle = (preferenceValue) => {
    if (
      dropdownFor === "shopifyPayments" ||
      dropdownFor === "accountsPaymentBank" ||
      dropdownFor === "contraAccount"
    ) {
      const field = fieldsData?.find(
        (fieldItem) => fieldItem.accountNumber === preferenceValue
      );

      if (field) {
        return field.name;
      } else {
        return "property deleted";
      }
    } else {
      const field = fieldsData?.find(
        (fieldItem) => fieldItem?.journalNumber === preferenceValue
      );

      if (field) {
        return field.name;
      } else {
        return "property deleted";
      }
    }
  };

  const toggleText = () => {
    if (
      dropdownFor === "shopifyPayments" ||
      dropdownFor === "accountsPaymentBank" ||
      dropdownFor === "contraAccount"
    ) {
      if (!selectedPreference || !selectedPreference.accountNumber) {
        return "Select";
      } else {
        return findSyncFieldsTitle(selectedPreference.accountNumber) ===
          "property deleted"
          ? "Property deleted"
          : findSyncFieldsTitle(selectedPreference.accountNumber);
      }
    } else if (dropdownFor === "journalAccount") {
      if (!selectedPreference || !selectedPreference.journalNumber) {
        return "Select";
      } else {
        return findSyncFieldsTitle(selectedPreference.journalNumber) ===
          "property deleted"
          ? "Property deleted"
          : findSyncFieldsTitle(selectedPreference.journalNumber);
      }
    }
  };

  return (
    <Dropdown
      className="dropdown mt-2"
      style={{ width: `${setWidth && "35%"}` }}
    >
      <Dropdown.Toggle
        className={`dropdown-box ${
          toggleText() === "Property deleted" ? "deleted-field" : ""
        }`}
      >
        {toggleText()}
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount={true} popperConfig={{ strategy: "fixed" }}>
        <div className="p-2">
          <div className="dropdown-search-container px-2">
            <span>
              <GoSearch size={20} className="me-2" />
            </span>
            <input
              type="search"
              value={searchInput}
              placeholder="Search"
              onChange={(e) => onSearchFields(e.target.value)}
              className="search-input"
            />
          </div>
          <hr />
          <div className="dropdown-menu-container">
            {searchInput.length > 0 && searchResults.length === 0 && (
              <div className="d-flex flex-row justify-content-center">
                <b>No results found</b>
              </div>
            )}

            {fieldsData && fieldsData.length > 0 ? (
              (searchInput.length > 0 ? searchResults : fieldsData).map(
                (fieldItem, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() =>
                      dispatch(
                        onChangeJournalDropdownAndFields({
                          selectingFor: dropdownFor,
                          selectedOption: fieldItem,
                        })
                      )
                    }
                  >
                    {fieldItem.name}
                  </Dropdown.Item>
                )
              )
            ) : (
              <div className="d-flex flex-row justify-content-center">
                <b>No fields found</b>
              </div>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FieldsDropdown;
