import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import { fetchStatus, setShowErrorModal } from "./preferenceSlice";

const initialState = {
  shopifyCustomerNumberPreference: {
    value: "shopify",
  },
  isContactsSyncRulesSaved: false,
  isContactDefaultMappingsSaved: false,
  economicFilterPreference: undefined,
  shopifyFilterPreference: undefined,
  economicCustomerPreference: {
    name: "e-conomic customer number",
    value: "ecocustomernumber",
  },
  shopifyCustomerPreference: undefined,
  economicCustomerDropdownFields: [
    {
      CRF: "ecocustomernumber",
      HRF: "e-conomic customer number",
    },
  ],
  shopifyCustomerNumberFields: [
    {
      title: "Let e-conomic create customer number",
      value: "economic",
    },
    {
      title: "Take customer number from Shopify",
      value: "shopify",
    },
  ],
  paymentTermPreference: undefined,
  currencyPreference: undefined,
  layoutPreference: undefined,
  customerGroupPreference: undefined,
  vatZonePreference: undefined,
  ecoDefaultFields: {
    loading: false,
    ecoDefaultFields: {},
    error: "",
  },
  deletedEcoDefaultFields: [],
};

export const fetchEcoDefaultFields = createAsyncThunk(
  "contact/fetchEcoDefaultFields",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_DEFAULT_ECO_FIELDS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    onChangeShopifyCustomerNumber(state, { payload }) {
      state.shopifyCustomerNumberPreference = {
        value: payload.selectedOption,
      };

      state.shopifyCustomerPreference = {
        name: "e-conomic customer number",
        value: "ecocustomernumber",
      };

      state.isContactsSyncRulesSaved = false;
    },

    onChangeContactDropdownFields(state, { payload }) {
      if (payload.selectingFor === "economicFilterDropdown") {
        state.economicFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactsSyncRulesSaved = false;
      } else if (payload.selectingFor === "shopifyFilterDropdown") {
        state.shopifyFilterPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactsSyncRulesSaved = false;
      } else if (payload.selectingFor === "shopifyCustomerNumber") {
        state.shopifyCustomerPreference = {
          name: payload.selectedOption.HRF,
          value: payload.selectedOption.CRF,
        };
        state.isContactsSyncRulesSaved = false;
      } else if (payload.selectingFor === "paymentTerms") {
        state.paymentTermPreference = {
          name: payload.selectedOption.title,
          value: Number(payload.selectedOption.value),
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "currency") {
        state.currencyPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "layout") {
        state.layoutPreference = {
          name: payload.selectedOption.title,
          value: Number(payload.selectedOption.value),
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "customerGroup") {
        state.customerGroupPreference = {
          name: payload.selectedOption.title,
          value: Number(payload.selectedOption.value),
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "vatZone") {
        state.vatZonePreference = {
          name: payload.selectedOption.title,
          value: Number(payload.selectedOption.value),
        };
        state.isContactDefaultMappingsSaved = false;
      }
    },

    setIsContactSyncRulesAndDefaultMappings(state, { payload }) {
      if (payload.type === "syncRules") {
        state.isContactsSyncRulesSaved = true;
      } else if (payload.type === "defaultMappings") {
        state.isContactDefaultMappingsSaved = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEcoDefaultFields.pending, (state, action) => {
      if (!state.ecoDefaultFields.loading) {
        state.ecoDefaultFields.loading = true;
      }
    });
    builder.addCase(fetchEcoDefaultFields.fulfilled, (state, action) => {
      if (state.ecoDefaultFields.loading) {
        state.ecoDefaultFields.loading = false;
        state.ecoDefaultFields.ecoDefaultFields = action.payload;
        state.ecoDefaultFields.error = "";
      }
    });
    builder.addCase(fetchEcoDefaultFields.rejected, (state, action) => {
      if (state.ecoDefaultFields.loading) {
        state.ecoDefaultFields.loading = false;
        state.ecoDefaultFields.error = action.error.message ?? "";
        state.ecoDefaultFields.ecoDefaultFields = {};
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preference } = action.payload;

      if (preference !== undefined && Object.keys(preference).length > 0) {
        const { customer } = preference;

        if (customer && Object.keys(customer).length > 0) {
          if (customer?.economicCustomerNumber) {
            state.shopifyCustomerNumberPreference = {
              value: customer.economicCustomerNumber.direction,
            };

            if (customer?.economicCustomerNumber?.mapping) {
              state.shopifyCustomerPreference = customer?.economicCustomerNumber
                ?.mapping?.shProperty?.value
                ? customer.economicCustomerNumber.mapping.shProperty
                : {
                    name: "e-conomic customer number",
                    value: "ecocustomernumber",
                  };
            }
          }

          if (customer?.filterOptions) {
            state.shopifyFilterPreference = customer?.filterOptions
              ?.filterSHProperty?.value
              ? customer.filterOptions.filterSHProperty
              : undefined;

            state.economicFilterPreference = customer?.filterOptions
              ?.filterEcoProperty?.value
              ? customer.filterOptions.filterEcoProperty
              : undefined;
          }

          if (customer?.economicFields) {
            const {
              paymentTerms,
              currencies,
              layouts,
              customerGroups,
              vatZones,
            } = customer.economicFields;

            state.paymentTermPreference = paymentTerms?.value
              ? { name: paymentTerms.name, value: Number(paymentTerms.value) }
              : undefined;
            state.currencyPreference = currencies?.value
              ? currencies
              : undefined;
            state.layoutPreference = layouts?.value
              ? { name: layouts?.name, value: Number(layouts?.value) }
              : undefined;
            state.customerGroupPreference = customerGroups?.value
              ? {
                  name: customerGroups.name,
                  value: Number(customerGroups.value),
                }
              : undefined;
            state.vatZonePreference = vatZones?.value
              ? { name: vatZones.name, value: Number(vatZones.value) }
              : undefined;
          }
        }
      }

      if (
        state.shopifyFilterPreference &&
        state.economicFilterPreference &&
        state.shopifyCustomerPreference &&
        state.shopifyCustomerNumberPreference
      ) {
        state.isContactsSyncRulesSaved = true;
      }

      if (
        state.paymentTermPreference &&
        state.vatZonePreference &&
        state.layoutPreference &&
        state.customerGroupPreference &&
        state.currencyPreference
      ) {
        state.isContactDefaultMappingsSaved = true;
      }
    });
  },
});

export const {
  onChangeShopifyCustomerNumber,
  onChangeContactDropdownFields,
  setIsContactSyncRulesAndDefaultMappings,
} = contactSlice.actions;

export default contactSlice.reducer;
