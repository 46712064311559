import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import MappingWindow from "../mapping/MappingWindow";

function FieldMappings() {
  const {
    mappings: {
      defaultMappings: { customer: mappings },
    },
    economic: {
      loading: economicLoading,
      economicDropdownFields: { customer: ecoFields },
    },
    shopify: {
      loading: shopifyLoading,
      shopifyDropdownFields: { customerFields: shopifyFields },
    },
  } = useSelector((state) => state.mapping);

  return (
    <div>
      {shopifyLoading || economicLoading ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div>
          {ecoFields &&
            ecoFields.length > 0 &&
            shopifyFields &&
            shopifyFields.length > 0 && (
              <MappingWindow
                mappings={mappings}
                ecoFields={ecoFields}
                shopifyFields={shopifyFields}
                mappingsFor="customer"
                notificationPath="contactFieldMappings"
                cmsPath="cmsContent.syncSettings.contact.fieldMappings"
              />
            )}
        </div>
      )}
    </div>
  );
}

export default FieldMappings;
