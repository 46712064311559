import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";

import {
  updateItemOffset,
  updateSelectedIndex,
  updateSlicedMappings,
  updatePageCount,
} from "../../store/slices/mappingSlice";

function Pagination({ mappingsFor, mappings }) {
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  // mapping states
  const {
    itemOffset,
    pageCount,
    selectedIndex,
    searchInput,
    searchResultsArray,
  } = useSelector((state) => state.mapping);
  // invoice state
  const { invoiceActiveTab } = useSelector((state) => state.preference);

  useEffect(() => {
    if (mappingsFor === "products" && invoiceActiveTab === "syncRules") {
      if (mappings.length > 0 && searchResultsArray.length > 0 && searchInput) {
        const endOffset = itemOffset + itemsPerPage;
        const filteredArray = mappings.filter((mappedItem) => {
          if (searchResultsArray.includes(mappedItem.id)) {
            return true;
          }
          return false;
        });
        dispatch(
          updateSlicedMappings({
            mappings: filteredArray.slice(itemOffset, endOffset),
          })
        );
        dispatch(
          updatePageCount({
            count: Math.ceil(searchResultsArray.length / itemsPerPage),
          })
        );
      } else if (mappings.length > 0) {
        const endOffset = itemOffset + itemsPerPage;
        dispatch(
          updateSlicedMappings({
            mappings: mappings.slice(itemOffset, endOffset),
          })
        );
        dispatch(
          updatePageCount({
            count: Math.ceil(mappings.length / itemsPerPage),
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, mappings, searchResultsArray]);

  useEffect(() => {
    if (mappingsFor === "products" && invoiceActiveTab === "syncRules") {
      if (mappings.length > 0 && searchResultsArray.length > 0 && searchInput) {
        const newOffset =
          (selectedIndex * itemsPerPage) % searchResultsArray.length;
        dispatch(updateItemOffset({ itemOffset: newOffset }));
      } else {
        const newOffset = (selectedIndex * itemsPerPage) % mappings.length;
        dispatch(updateItemOffset({ itemOffset: newOffset }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, mappings]);

  useEffect(() => {
    if (
      searchResultsArray.length > 0 &&
      mappingsFor === "products" &&
      invoiceActiveTab === "syncRules"
    ) {
      dispatch(updateSelectedIndex({ selectedIndex: 0 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultsArray]);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="Next"
      onPageChange={(event) =>
        dispatch(updateSelectedIndex({ selectedIndex: event.selected }))
      }
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel="Previous"
      renderOnZeroPageCount={null}
      containerClassName="pagination-container"
      pageLinkClassName="page-num"
      previousLinkClassName="page-num"
      nextLinkClassName="page-num"
      activeLinkClassName="active"
      disabledLinkClassName="disabled"
      marginPagesDisplayed={3}
      forcePage={selectedIndex}
    />
  );
}

export default Pagination;
