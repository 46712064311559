import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import {
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Button } from "@shopify/polaris";

import {
  onChangePreferenceForSearchParameters,
  onChangePreferenceForProducts,
  onChangePreferenceForCreatingProduct,
  setIsProductSyncRulesSaved,
} from "../../store/slices/productSlice";

import FieldsDropdown from "../FieldsDropdown";
import MappingWindow from "../mapping/MappingWindow";

import { checkCountForProductMapped } from "../invoices/SyncRules";

import { useCmsHook } from "../../helpers/cmsHook";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";

const SyncRules = () => {
  const dispatch = useDispatch();

  const { cmsInstance } = useCmsHook();

  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  // product states
  const {
    shopifyProductFilterPreference,
    economicProductFilterPreference,
    preferenceForSearchParameters,
    preferenceForProducts,
    defaultProductPreference,
    preferenceForCreatingProduct,
    defaultProductForCreating,
    productGroupPreference,
  } = useSelector((state) => state.product);

  const {
    shopify: {
      loading: shopifyLoading,
      shopifyDropdownFields: {
        products: shopifyProducts,
        productFields: shopifyProductSearchFields,
      },
    },
    economic: {
      loading: economicLoading,
      economicDropdownFields: {
        products: economicProducts,
        productFields: economicProductSearchFields,
      },
    },
    mappings: {
      defaultMappings: { products: productMappings },
    },
    isMappingsSaved: { products: isMappingsSaved },
  } = useSelector((state) => state.mapping);

  const {
    ecoDefaultFields: {
      ecoDefaultFields: { productGroups },
    },
  } = useSelector((state) => state.contact);

  return (
    <div>
      {economicLoading || shopifyLoading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.product.syncRules.section1.text"
              )}
            <div className="mt-3">
              <Form.Check
                type="radio"
                id="selectSearchParameters"
                name="productSearchParameters"
                value="selectSearchParameters"
                label="Select search parameters"
                className="radio-button my-2"
                checked={
                  preferenceForSearchParameters === "selectSearchParameters"
                }
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForSearchParameters({
                      selected: e.target.value,
                    })
                  )
                }
              />
              {preferenceForSearchParameters === "selectSearchParameters" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  {preferenceForSearchParameters === "selectSearchParameters" &&
                    shopifyProductSearchFields &&
                    economicProductSearchFields &&
                    shopifyProductSearchFields.length > 0 &&
                    economicProductSearchFields.length > 0 && (
                      <Row>
                        {cmsInstance &&
                          cmsInstance.showRichText(
                            "cmsContent.syncSettings.product.syncRules.section1.radioBtns.searchParameters"
                          )}
                        <Col lg={3} md={5}>
                          <p className="dropdown-header">Shopify</p>
                          <FieldsDropdown
                            fieldsData={shopifyProductSearchFields}
                            dropdownFor="shopifyProductFilterDropdown"
                            selectedPreference={shopifyProductFilterPreference}
                          />
                        </Col>
                        <Col lg={3} md={5}>
                          <p className="dropdown-header">e-conomic</p>
                          <FieldsDropdown
                            fieldsData={economicProductSearchFields}
                            dropdownFor="economicProductFilterDropdown"
                            selectedPreference={economicProductFilterPreference}
                          />
                        </Col>
                      </Row>
                    )}
                </div>
              )}
            </div>
            <div className="mt-2">
              <Form.Check
                type="radio"
                id="setCustomMappings"
                name="productSearchParameters"
                value="setCustomMappings"
                label="Set custom mappings"
                className="radio-button"
                checked={preferenceForSearchParameters === "setCustomMappings"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForSearchParameters({
                      selected: e.target.value,
                    })
                  )
                }
              />
              {preferenceForSearchParameters === "setCustomMappings" && (
                <div>
                  {shopifyProducts &&
                  economicProducts &&
                  shopifyProducts.length > 0 &&
                  economicProducts.length > 0 ? (
                    <MappingWindow
                      mappings={productMappings}
                      ecoFields={economicProducts}
                      shopifyFields={shopifyProducts}
                      mappingsFor="products"
                      notificationPath="productMappings"
                      cmsPath="cmsContent.syncSettings.product.syncRules.productFieldMappings"
                    />
                  ) : (
                    <div className="search-not-found-container py-3 text-center">
                      <p>
                        Products not present either in Shopify or in e-conomic.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.product.syncRules.section2.text"
              )}
            <div className="mt-3">
              <Form.Check
                key="doNotGenerateInvoice"
                type="radio"
                id="doNotGenerateInvoice"
                name="productRadioButton"
                value="doNotGenerateInvoice"
                label="Don't generate invoice"
                className="radio-button my-2"
                checked={preferenceForProducts === "doNotGenerateInvoice"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForProducts({
                      selected: e.target.value,
                    })
                  )
                }
              />
              {preferenceForProducts === "doNotGenerateInvoice" && (
                <div
                  style={{
                    marginLeft: "21px",
                    marginRight: "21px",
                  }}
                >
                  <Row>
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        "cmsContent.syncSettings.product.syncRules.section2.radioBtns.doNotGenerateInvoice"
                      )}
                  </Row>
                </div>
              )}
            </div>
            <div className="mt-2">
              <Form.Check
                key="useDefaultProduct"
                type="radio"
                id="useDefaultProduct"
                name="productRadioButton"
                value="useDefaultProduct"
                label="Use a pre-chosen product"
                className="radio-button my-2"
                checked={preferenceForProducts === "useDefaultProduct"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForProducts({ selected: e.target.value })
                  )
                }
              />
              {preferenceForProducts === "useDefaultProduct" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  {economicProducts && economicProducts.length > 0 ? (
                    <Row>
                      {cmsInstance &&
                        cmsInstance.showRichText(
                          "cmsContent.syncSettings.product.syncRules.section2.radioBtns.defaultProduct"
                        )}
                      <Col lg={3} md={5}>
                        <p className="dropdown-header">Select product</p>
                        <FieldsDropdown
                          fieldsData={economicProducts}
                          dropdownFor="defaultProduct"
                          selectedPreference={defaultProductPreference}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <div className="search-not-found-container py-3 text-center">
                      <p>No products present in e-conomic.</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-2">
              <Form.Check
                key="findCreateProduct"
                type="radio"
                id="findCreateProduct"
                name="productRadioButton"
                value="findCreateProduct"
                label="Create new product"
                className="radio-button my-2"
                checked={preferenceForProducts === "findCreateProduct"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForProducts({ selected: e.target.value })
                  )
                }
              />
              {preferenceForProducts === "findCreateProduct" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  <Row>
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        "cmsContent.syncSettings.product.syncRules.section2.radioBtns.createNewProduct"
                      )}
                  </Row>
                </div>
              )}
              {preferenceForProducts === "findCreateProduct" && (
                <div
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                  className="mt-2"
                >
                  <div className="mb-4">
                    {productGroups && productGroups.length > 0 && (
                      <Row>
                        <Col lg={3} md={5}>
                          <p className="dropdown-header">
                            Select Product Group
                          </p>
                          <FieldsDropdown
                            fieldsData={productGroups}
                            dropdownFor="productGroup"
                            selectedPreference={productGroupPreference}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="createProduct"
                      label="You have custom products in your orders"
                      checked={preferenceForCreatingProduct}
                      onChange={(e) =>
                        dispatch(onChangePreferenceForCreatingProduct())
                      }
                    />
                    {preferenceForCreatingProduct && (
                      <div
                        style={{ marginLeft: "25px", marginRight: "25px" }}
                        className="mt-2"
                      >
                        {economicProducts && economicProducts.length > 0 ? (
                          <Row>
                            <Col lg={3} md={5}>
                              <p className="dropdown-header">Select product</p>
                              <FieldsDropdown
                                fieldsData={economicProducts}
                                dropdownFor="product"
                                selectedPreference={defaultProductForCreating}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <div className="search-not-found-container py-3 text-center">
                            <p>No products present in e-conomic.</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-2">
            <div className="d-flex flex-row justify-content-end mt-4 mb-2">
              {(preferenceForProducts === "useDefaultProduct" &&
                !defaultProductPreference) ||
              (preferenceForCreatingProduct === true &&
                !defaultProductForCreating) ||
              (preferenceForSearchParameters === "selectSearchParameters" &&
                (!shopifyProductFilterPreference ||
                  !economicProductFilterPreference)) ||
              (preferenceForSearchParameters === "setCustomMappings" &&
                (checkCountForProductMapped({ mappings: productMappings }) ===
                  0 ||
                  (checkCountForProductMapped({ mappings: productMappings }) >
                    0 &&
                    !isMappingsSaved))) ? (
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip>
                      {showToolTipText(
                        preferenceForProducts,
                        defaultProductPreference,
                        preferenceForCreatingProduct,
                        defaultProductForCreating,
                        preferenceForSearchParameters,
                        shopifyProductFilterPreference,
                        economicProductFilterPreference,
                        productMappings,
                        isMappingsSaved
                      )}
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      className="sync-settings-button"
                      style={{ cursor: "not-allowed" }}
                      disabled
                    >
                      Save changes
                    </Button>
                  </div>
                </OverlayTrigger>
              ) : (
                <Button
                  primary
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => {
                    onSaveProductSyncRules({
                      setIsLoading,
                      searchParams,
                      dispatch,
                      cmsInstance,
                      preferenceForProducts,
                      defaultProductPreference,
                      preferenceForCreatingProduct,
                      defaultProductForCreating,
                      preferenceForSearchParameters,
                      shopifyProductFilterPreference,
                      economicProductFilterPreference,
                      setIsProductSyncRulesSaved,
                      productGroupPreference,
                    });
                  }}
                >
                  Save changes
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncRules;

export const onSaveProductSyncRules = async ({
  setIsLoading,
  searchParams,
  dispatch,
  cmsInstance,
  preferenceForProducts,
  defaultProductPreference,
  preferenceForCreatingProduct,
  defaultProductForCreating,
  preferenceForSearchParameters,
  shopifyProductFilterPreference,
  economicProductFilterPreference,
  productGroupPreference,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");
    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,
      preferences: {
        product: {
          generateInvoice: {
            create:
              preferenceForProducts === "doNotGenerateInvoice" ||
              preferenceForProducts === "findCreateProduct"
                ? false
                : true,
            mapping: {
              ecoDefaultProduct:
                preferenceForProducts === "useDefaultProduct" &&
                defaultProductPreference?.value
                  ? defaultProductPreference
                  : {},
            },
          },
          createProduct: {
            create:
              preferenceForProducts === "findCreateProduct" ? true : false,
            customProduct: {
              useEcoDefaultProduct:
                preferenceForProducts === "findCreateProduct" &&
                preferenceForCreatingProduct
                  ? true
                  : false,
              mapping: {
                ecoDefaultProduct:
                  preferenceForCreatingProduct &&
                  preferenceForProducts === "findCreateProduct" &&
                  defaultProductForCreating?.value
                    ? defaultProductForCreating
                    : {},
              },
            },
          },
          productSearchParameter:
            preferenceForSearchParameters === "selectSearchParameters"
              ? true
              : false,
          filterOptions: {
            filterSHProperty: shopifyProductFilterPreference,
            filterEcoProperty: economicProductFilterPreference,
          },
          economicFields: {
            productGroups: productGroupPreference,
          },
        },
      },
      preferenceType: "product",
    });
    dispatch(setIsProductSyncRulesSaved());
    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.productSyncRules.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};

const showToolTipText = (
  preferenceForProducts,
  defaultProductPreference,
  preferenceForCreatingProduct,
  defaultProductForCreating,
  preferenceForSearchParameters,
  shopifyProductFilterPreference,
  economicProductFilterPreference,
  productMappings,
  isMappingsSaved
) => {
  if (
    preferenceForProducts === "useDefaultProduct" &&
    !defaultProductPreference
  ) {
    return "Please select the default product for using it as default product";
  } else if (
    preferenceForCreatingProduct === true &&
    !defaultProductForCreating
  ) {
    return "Please select the default product for find/create product";
  } else if (
    preferenceForSearchParameters === "selectSearchParameters" &&
    !shopifyProductFilterPreference
  ) {
    return "Please select shopify search parameter";
  } else if (
    preferenceForSearchParameters === "selectSearchParameters" &&
    economicProductFilterPreference
  ) {
    return "Please select e-conomic search parameter";
  } else if (
    preferenceForSearchParameters === "setCustomMappings" &&
    checkCountForProductMapped({ mappings: productMappings }) === 0
  ) {
    return "Please map at least one product in the mapping window";
  } else if (
    preferenceForSearchParameters === "setCustomMappings" &&
    checkCountForProductMapped({ mappings: productMappings }) &&
    !isMappingsSaved
  ) {
    return "Please save the product mappings";
  }
};
