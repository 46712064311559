import React, { useState, useEffect } from "react";
import { Navbar, Container, Col, Button, Card, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { FaCheckCircle } from "react-icons/fa";
import { ImRadioChecked2 } from "react-icons/im";

import { getUrl } from "../helpers/url";

const Retrigger = () => {
  const data = {
    triggered: {
      label: "Triggered",
      value: undefined,
    },
    findCreateCustomer: {
      label: "Find Create Customer",
      value: undefined,
    },
    findProductsInEco: {
      label: "Find Products In Economic",
      value: undefined,
    },
    syncOrder: {
      label: "Sync Order",
      value: undefined,
    },
  };

  const [searchParams] = useSearchParams();
  const [stageData, setStageData] = useState(data);
  const [retriggeredNote, setRetriggeredNote] = useState("");
  const [dealStage, setDealStage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const stageName = searchParams.get("stageName");
    const errorLog = searchParams.get("errorLog");
    if (errorLog) {
      setRetriggeredNote(
        `The workflow couldn't run because of an error: ${errorLog}`
      );
    }
    setDealStage(stageName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dealStage) {
      for (let stage in stageData) {
        stageData[stage].value = undefined;
      }
      setStageData({ ...stageData });

      for (let stage in stageData) {
        if (stage === dealStage) {
          stageData[stage].value = false;
          break;
        } else {
          stageData[stage].value = true;
        }
      }
    }
    setStageData({ ...stageData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealStage]);

  const renderIcons = (value) => {
    if (value === true) {
      return <FaCheckCircle size={25} className="me-3 logs-success-icon" />;
    } else if (value === false) {
      return <ImRadioChecked2 size={25} className="me-3 logs-failed-icon" />;
    } else {
      return <div className="logs-empty-icon me-3"></div>;
    }
  };

  const onRetriggerWorkflow = async () => {
    try {
      setIsLoading(true);
      const shop = searchParams.get("shop");
      const orderId = searchParams.get("orderId");

      const { data: response } = await axios.post(
        getUrl("REACT_APP_RETRIGGER"),
        {
          shop,
          orderId,
          retriggered: true,
        }
      );

      let displayMsg = "";
      if (
        response.msg ===
        "e-conomic draft invoice for this Opportunity has already been successfully generated"
      ) {
        displayMsg =
          "e-conomic draft invoice for this Opportunity has already been successfully generated";
      } else {
        displayMsg =
          "The e-conomic draft invoice has been generated successfully";
      }

      for (let stage in stageData) {
        stageData[stage].value = true;
      }
      setStageData({ ...stageData });
      setDealStage("");

      setRetriggeredNote(displayMsg);

      toast.success("Retrigger Success", {
        className: "react-toast-success",
      });
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      setDealStage(error.response.data.errorStage);
      setRetriggeredNote(
        `The workflow couldn't run because of an error: ${errorMessage}`
      );
      toast.error("Retrigger Failed", {
        className: "react-toast-error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar fixed="top" className="navbar-container">
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <h5>Visma e-conomic sync</h5>
        </div>
      </Navbar>
      <Container className="d-flex flex-column justify-content-center">
        <div
          style={{ marginTop: "60px" }}
          className="d-flex flex-column justify-content-center align-items-center retrigger-container"
        >
          <Col md={8} className="mb-5">
            {/* {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.retrigger.description",
                "text-center mb-0"
              )} */}
          </Col>
          <Col md={6} className="d-flex flex-row justify-content-center">
            <div className="w-100">
              {retriggeredNote && (
                <Card
                  className="e-conomic-card"
                  style={{ marginBottom: "27px", marginTop: "0px" }}
                >
                  <Card.Body>
                    <p className="my-1">
                      <b>Note: </b> <br />
                      {retriggeredNote}
                    </p>
                  </Card.Body>
                </Card>
              )}

              <div className="d-flex flex-row justify-content-center w-100">
                <div>
                  {stageData &&
                    Object.keys(stageData).length > 0 &&
                    Object.keys(stageData).map((stage) => (
                      <div key={stage}>
                        <div className="d-flex flex-row align-items-center">
                          {renderIcons(stageData[stage].value)}
                          <b>{stageData[stage].label}</b>
                          {/* {cmsInstance &&
                            cmsInstance.showRichText(
                              `cmsContent.retrigger.${stageData[stage].label}`,
                              "retrigger-window"
                            )} */}
                        </div>
                        {stage !== "syncOrder" && (
                          <div
                            style={{
                              borderLeft: `${
                                stageData[stage].value === true
                                  ? "4px solid #08a742"
                                  : "4px solid #d9d9d9"
                              }`,
                              height: "30px",
                              marginLeft: "10px",
                            }}
                          ></div>
                        )}
                      </div>
                    ))}
                  <div className="d-flex flex-row justify-content-center">
                    <Button
                      className="mt-5 sync-settings-button"
                      disabled={isLoading || !dealStage}
                      onClick={() => onRetriggerWorkflow()}
                    >
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Replay</span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default Retrigger;
