import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { GoSearch } from "react-icons/go";

import { onChangeContactDropdownFields } from "../store/slices/contactSlice";
import { onChangeInvoiceDropdownFields } from "../store/slices/invoiceSlice";
import { onChangeProductPreference } from "../store/slices/productSlice";

const FieldsDropdown = ({
  fieldsData,
  dropdownFor,
  selectedPreference,
  isDisabled,
  setWidth,
  selectedPipelines,
}) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const disableSelectedPipelines = (value) => {
    return selectedPipelines.find((item) => item.pipeline.value === value);
  };

  const onSearchFields = (searchValue) => {
    setSearchInput(searchValue);
    let searchArray;
    if (
      dropdownFor === "shopifyFilterDropdown" ||
      dropdownFor === "shopifyCustomerNumber" ||
      dropdownFor === "shopifyProductFilterDropdown" ||
      dropdownFor === "economicFilterDropdown" ||
      dropdownFor === "economicCustomerNumber" ||
      dropdownFor === "economicProductFilterDropdown" ||
      dropdownFor === "defaultProduct" ||
      dropdownFor === "product" ||
      dropdownFor === "orderDiscount" ||
      dropdownFor === "shippingDischarge"
    ) {
      searchArray = fieldsData?.filter((fieldItem) =>
        fieldItem.HRF.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      searchArray = fieldsData?.filter((fieldItem) =>
        fieldItem.title.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    setSearchResults(searchArray);
  };

  const findSyncFieldsTitle = (preferenceValue) => {
    if (
      dropdownFor === "shopifyFilterDropdown" ||
      dropdownFor === "economicFilterDropdown" ||
      dropdownFor === "shopifyCustomerNumber" ||
      dropdownFor === "economicCustomerNumber" ||
      dropdownFor === "economicProductFilterDropdown" ||
      dropdownFor === "shopifyProductFilterDropdown" ||
      dropdownFor === "defaultProduct" ||
      dropdownFor === "product" ||
      dropdownFor === "orderDiscount" ||
      dropdownFor === "shippingDischarge"
    ) {
      const field = fieldsData?.find(
        (fieldItem) => fieldItem.CRF === preferenceValue
      );

      if (field) {
        return field.HRF;
      } else {
        return "property deleted";
      }
    } else {
      const field = fieldsData?.find(
        (fieldItem) =>
          fieldItem?.value?.toString() === preferenceValue.toString()
      );
      if (field) {
        return field.title;
      } else {
        return "property deleted";
      }
    }
  };

  const toggleText = () => {
    if (
      dropdownFor === "shopifyFilterDropdown" ||
      dropdownFor === "shopifyCustomerNumber" ||
      dropdownFor === "shopifyProductFilterDropdown"
    ) {
      if (!selectedPreference || !selectedPreference.value) {
        return "Select Shopify property";
      } else {
        return findSyncFieldsTitle(selectedPreference.value) ===
          "property deleted"
          ? "shopify property deleted"
          : findSyncFieldsTitle(selectedPreference.value);
      }
    } else if (
      dropdownFor === "economicFilterDropdown" ||
      dropdownFor === "economicCustomerNumber" ||
      dropdownFor === "economicProductFilterDropdown"
    ) {
      if (!selectedPreference || !selectedPreference.value) {
        return "Select e-conomic property";
      } else {
        return findSyncFieldsTitle(selectedPreference.value);
      }
    } else if (
      dropdownFor === "paymentTerms" ||
      dropdownFor === "currency" ||
      dropdownFor === "layout" ||
      dropdownFor === "vatZone" ||
      dropdownFor === "customerGroup" ||
      dropdownFor === "productGroup"
    ) {
      if (!selectedPreference || !selectedPreference.value) {
        return "Select";
      } else {
        return findSyncFieldsTitle(selectedPreference.value);
      }
    } else if (
      dropdownFor === "defaultProduct" ||
      dropdownFor === "product" ||
      dropdownFor === "orderDiscount" ||
      dropdownFor === "shippingDischarge"
    ) {
      if (!selectedPreference || !selectedPreference.value) {
        return "Select product";
      } else {
        return findSyncFieldsTitle(selectedPreference.value) ===
          "property deleted"
          ? "product deleted"
          : `${findSyncFieldsTitle(
              selectedPreference.value
            )} (Product Number: ${selectedPreference.value})`;
      }
    } else if (
      dropdownFor === "fulfillmentStatus" ||
      dropdownFor === "paymentStatus"
    ) {
      if (!selectedPreference || !selectedPreference.value) {
        return "Select status";
      } else {
        return findSyncFieldsTitle(selectedPreference.value) ===
          "property deleted"
          ? "status deleted"
          : findSyncFieldsTitle(selectedPreference.value);
      }
    }
  };

  const onChangePreferences = (dropdownFor, fieldItem) => {
    if (
      dropdownFor === "orderDiscount" ||
      dropdownFor === "shippingDischarge"
    ) {
      dispatch(
        onChangeInvoiceDropdownFields({
          selectingFor: dropdownFor,
          selectedOption: fieldItem,
        })
      );
    } else if (
      dropdownFor === "economicProductFilterDropdown" ||
      dropdownFor === "shopifyProductFilterDropdown" ||
      dropdownFor === "defaultProduct" ||
      dropdownFor === "product" ||
      dropdownFor === "productGroup"
    ) {
      dispatch(
        onChangeProductPreference({
          selectingFor: dropdownFor,
          selectedOption: fieldItem,
        })
      );
    } else {
      dispatch(
        onChangeContactDropdownFields({
          selectingFor: dropdownFor,
          selectedOption: fieldItem,
        })
      );
    }
  };

  return (
    <Dropdown
      className="dropdown mt-1"
      style={{
        cursor: `${isDisabled && "not-allowed"}`,
        width: `${setWidth && "50%"}`,
      }}
    >
      <Dropdown.Toggle
        className={`dropdown-box ${
          toggleText() === "property deleted" ||
          toggleText() === "status deleted" ||
          toggleText() === "shopify property deleted" ||
          toggleText() === "product deleted"
            ? "deleted-field"
            : ""
        }`}
        disabled={isDisabled}
      >
        {toggleText()}
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount={true} popperConfig={{ strategy: "fixed" }}>
        <div className="p-2">
          <div className="dropdown-search-container px-2">
            <span>
              <GoSearch size={20} className="me-2" />
            </span>
            <input
              type="search"
              value={searchInput}
              placeholder="Search"
              onChange={(e) => onSearchFields(e.target.value)}
              className="search-input"
            />
          </div>
          <hr />
          {dropdownFor === "shopifyFilterDropdown" ||
          dropdownFor === "shopifyCustomerNumber" ||
          dropdownFor === "shopifyProductFilterDropdown" ||
          dropdownFor === "economicFilterDropdown" ||
          dropdownFor === "economicCustomerNumber" ||
          dropdownFor === "economicProductFilterDropdown" ||
          dropdownFor === "defaultProduct" ||
          dropdownFor === "product" ||
          dropdownFor === "orderDiscount" ||
          dropdownFor === "shippingDischarge" ||
          dropdownFor === "productGroup" ? (
            <div className="dropdown-menu-container">
              {searchInput.length > 0 && searchResults.length === 0 && (
                <div className="d-flex flex-row justify-content-center">
                  <b>No results found</b>
                </div>
              )}

              {fieldsData && fieldsData.length > 0 ? (
                (searchInput.length > 0 ? searchResults : fieldsData).map(
                  (fieldItem, index) => (
                    <Dropdown.Item
                      key={index}
                      disabled={
                        dropdownFor === "pipelineDropdown" &&
                        disableSelectedPipelines(fieldItem.CRF)
                      }
                      className="dropdown-item"
                      onClick={() =>
                        onChangePreferences(dropdownFor, fieldItem)
                      }
                    >
                      {dropdownFor === "defaultProduct" ||
                      dropdownFor === "product" ||
                      dropdownFor === "orderDiscount" ||
                      dropdownFor === "shippingDischarge" ? (
                        <span>
                          {fieldItem.HRF}{" "}
                          <span style={{ fontSize: "12px" }}>
                            (Product Number: {fieldItem.CRF})
                          </span>
                        </span>
                      ) : (
                        <span>
                          {dropdownFor === "productGroup"
                            ? fieldItem.title
                            : fieldItem.HRF}
                        </span>
                      )}
                    </Dropdown.Item>
                  )
                )
              ) : (
                <div className="d-flex flex-row justify-content-center">
                  <b>No fields found</b>
                </div>
              )}
            </div>
          ) : (
            <div className="dropdown-menu-container">
              {searchInput.length > 0 && searchResults.length === 0 && (
                <div className="d-flex flex-row justify-content-center">
                  <b>No results found</b>
                </div>
              )}
              {fieldsData && fieldsData.length > 0 ? (
                (searchInput.length > 0 ? searchResults : fieldsData).map(
                  (fieldItem, index) => (
                    <Dropdown.Item
                      key={index}
                      className="dropdown-item"
                      onClick={() => {
                        dropdownFor === "fulfillmentStatus" ||
                        dropdownFor === "paymentStatus"
                          ? dispatch(
                              onChangeInvoiceDropdownFields({
                                selectingFor: dropdownFor,
                                selectedOption: fieldItem,
                              })
                            )
                          : dispatch(
                              onChangeContactDropdownFields({
                                selectingFor: dropdownFor,
                                selectedOption: fieldItem,
                              })
                            );
                      }}
                    >
                      {fieldItem.title}
                    </Dropdown.Item>
                  )
                )
              ) : (
                <div className="d-flex flex-row justify-content-center">
                  <b>No fields found</b>
                </div>
              )}
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FieldsDropdown;
