import { useEffect } from "react";
import { Navbar, Spinner, Col } from "react-bootstrap";

import { CmsConstructor } from "./cmsConstructor";
import { useCmsHook } from "./cmsHook";
import CmsEditAndSave from "./CmsEditAndSave";

const CmsNotifications = () => {
  const { cmsInstance, setCmsInstance } = useCmsHook();

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor();
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSuccessAndFailedNotifications = (
    message,
    key,
    notificationKey
  ) => {
    const notificationKeys = Object.keys(message);
    const notificationMessages = notificationKeys.map((notification, index) => {
      const description = message[notification].description;
      const messagePath = `cmsContent.notifications.${key}.${notificationKey}.${notification}.message`;

      return (
        <div key={index}>
          {description && <h5 className="mt-4 mb-0">{description}</h5>}
          {cmsInstance && cmsInstance.showRichText(messagePath)}
        </div>
      );
    });

    return notificationMessages;
  };

  const renderNotifications = (notificationContent, key, notificationKey) => {
    const content = notificationContent[notificationKey];
    if (content) {
      return renderSuccessAndFailedNotifications(content, key, notificationKey);
    }
  };

  const renderBlocks = () => {
    if (cmsInstance) {
      const {
        cmsContent: { notifications },
      } = cmsInstance.sendData();

      if (notifications) {
        const notificationBlocks = Object.keys(notifications).map(
          (key, index) => (
            <Col
              md={8}
              className="p-4 mb-4"
              key={index}
              style={{ border: "3px solid #e4e6e9" }}
            >
              {notifications[key].heading && (
                <h4 className="">{notifications[key].heading}</h4>
              )}
              {renderNotifications(notifications[key], key, "successContent")}
              {renderNotifications(notifications[key], key, "failContent")}
            </Col>
          )
        );

        return notificationBlocks;
      }
    }

    return null;
  };

  return (
    <div>
      <Navbar fixed="top" className="navbar-container">
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <h5>Visma e-conomic sync</h5>
        </div>
      </Navbar>
      {!cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100">
          <div style={{ marginTop: "90px" }}>
            <CmsEditAndSave />
          </div>
          <div className="d-flex flex-column align-items-center mt-4 pb-5 cms-notifications">
            {renderBlocks()}
          </div>
        </div>
      )}
    </div>
  );
};

export default CmsNotifications;
