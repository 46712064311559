import React, { useEffect } from "react";

import ConnectEconomic from "./ConnectEconomic";

import { Provider } from "@shopify/app-bridge-react";

import { CmsConstructor } from "../helpers/cmsConstructor";
import { useCmsHook } from "../helpers/cmsHook";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const Connect = () => {
  const { setCmsInstance } = useCmsHook();

  const config = {
    apiKey: process.env.REACT_APP_SHOPIFY_CLIENT,
    host: new URLSearchParams(window.location.search).get("host"),
  };

  useEffect(() => {
    if (window.location.pathname === "/connect") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor();
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider config={config}>
      <div>
        <CmsEditAndSave />
      </div>
      <ConnectEconomic />
    </Provider>
  );
};

export default Connect;
