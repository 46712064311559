import React from "react";

import { Toast } from "@shopify/polaris";

import { useSelector, useDispatch } from "react-redux";

import { onHideSuccessModal } from "../../store/slices/preferenceSlice";

const SuccessToast = () => {
  const dispatch = useDispatch();
  const { successMessage, showSuccessToast } = useSelector(
    (state) => state.preference
  );

  return (
    <>
      {showSuccessToast && (
        <Toast
          content={successMessage}
          onDismiss={() => dispatch(onHideSuccessModal())}
        />
      )}
    </>
  );
};

export default SuccessToast;
