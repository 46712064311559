import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getUrl } from "../../helpers/url";

import { fetchStatus, setShowErrorModal } from "./preferenceSlice";

const initialState = {
  createJournalEntries: false,
  isJournalEntriesSaved: true,
  journalAccountPreference: undefined,
  accountsShopifyPaymentPreference: undefined,
  shopifyPaymentsText: "",
  accountsPaymentBankPreference: undefined,
  paymentBankText: "",
  customerPaymentText: "",
  journals: {
    loading: false,
    journals: [],
    error: "",
  },
  accounts: {
    loading: false,
    accounts: [],
    error: "",
  },
};

export const fetchJournals = createAsyncThunk(
  "journalEntries/fetchJournals",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_JOURNALS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    }
  }
);

export const fetchAccounts = createAsyncThunk(
  "journalEntries/fetchAccounts",
  async ({ shop }, { dispatch }) => {
    try {
      const { data: response } = await axios.get(
        getUrl("REACT_APP_GET_ACCOUNTS"),
        {
          params: {
            shop,
          },
        }
      );
      return response;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
    }
  }
);

export const journalEntriesSlice = createSlice({
  name: "journalEntries",
  initialState,
  reducers: {
    setIsJournalEntriesSaved(state, { payload }) {
      state.isJournalEntriesSaved = true;
    },

    onChangeCreateJournalEntries(state, { payload }) {
      state.createJournalEntries = !state.createJournalEntries;
      if (state.createJournalEntries) {
        state.isJournalEntriesSaved = false;
      } else if (state.createJournalEntries === false) {
        state.isJournalEntriesSaved = true;
      }
    },

    onChangeJournalDropdownAndFields(state, { payload }) {
      if (payload.selectingFor === "journalAccount") {
        state.journalAccountPreference = {
          name: payload.selectedOption.name,
          journalNumber: payload.selectedOption.journalNumber,
        };
      } else if (payload.selectingFor === "shopifyPayments") {
        state.accountsShopifyPaymentPreference = {
          name: payload.selectedOption.name,
          accountNumber: payload.selectedOption.accountNumber,
        };
      } else if (payload.selectingFor === "accountsPaymentBank") {
        state.accountsPaymentBankPreference = {
          name: payload.selectedOption.name,
          accountNumber: payload.selectedOption.accountNumber,
        };
      }
      state.isJournalEntriesSaved = false;
    },

    onChangeTextFields(state, { payload }) {
      if (payload.selectingFor === "shopifyPayments") {
        state.shopifyPaymentsText = payload.text;
      } else if (payload.selectingFor === "paymentsBank") {
        state.paymentBankText = payload.text;
      } else if (payload.selectingFor === "customerPayment") {
        state.customerPaymentText = payload.text;
      }
      state.isJournalEntriesSaved = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJournals.pending, (state, action) => {
      if (!state.journals.loading) {
        state.journals.loading = true;
      }
    });
    builder.addCase(fetchJournals.fulfilled, (state, action) => {
      if (state.journals.loading) {
        state.journals.loading = false;
        state.journals.journals = action.payload;
        state.journals.error = "";
      }
    });
    builder.addCase(fetchJournals.rejected, (state, action) => {
      if (state.journals.loading) {
        state.journals.loading = false;
        state.journals.journals = [];
        state.journals.journals = action.error.message ?? "";
      }
    });

    builder.addCase(fetchAccounts.pending, (state, action) => {
      if (!state.accounts.loading) {
        state.accounts.loading = true;
      }
    });
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      if (state.accounts.loading) {
        state.accounts.loading = false;
        state.accounts.accounts = action.payload;
        state.accounts.error = "";
      }
    });
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      if (state.accounts.loading) {
        state.accounts.loading = false;
        state.accounts.accounts = [];
        state.accounts.error = action.error.message ?? "";
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preference } = action.payload;
      const { journal } = preference;
      if (journal && Object.keys(journal).length > 0) {
        const { createJournal, associate } = journal;

        state.createJournalEntries = associate;

        if (createJournal && Object.keys(createJournal).length > 0) {
          const {
            shopifyAccount,
            paymentAccountText,
            journalNumber,
            shopifyAccountText,
            paymentAccount,
            customerAccountText,
          } = createJournal;

          state.journalAccountPreference =
            journalNumber && journalNumber?.value
              ? {
                  name: journalNumber?.name,
                  journalNumber: parseInt(journalNumber?.value),
                }
              : undefined;
          state.accountsShopifyPaymentPreference =
            shopifyAccount && shopifyAccount.value
              ? {
                  name: shopifyAccount.name,
                  accountNumber: parseInt(shopifyAccount.value),
                }
              : undefined;
          state.accountsPaymentBankPreference =
            paymentAccount && paymentAccount?.value
              ? {
                  name: paymentAccount.name,
                  accountNumber: parseInt(paymentAccount.value),
                }
              : undefined;
          state.shopifyPaymentsText =
            shopifyAccountText && shopifyAccountText.length > 0
              ? shopifyAccountText
              : "";
          state.paymentBankText =
            paymentAccountText && paymentAccountText.length > 0
              ? paymentAccountText
              : "";
          state.customerPaymentText =
            customerAccountText && customerAccountText.length > 0
              ? customerAccountText
              : "";
        }
      }
      state.isJournalEntriesSaved = true;
    });
  },
});

export const {
  setIsJournalEntriesSaved,
  onChangeJournalDropdownAndFields,
  onChangeTextFields,
  onChangeCreateJournalEntries,
} = journalEntriesSlice.actions;

export default journalEntriesSlice.reducer;
