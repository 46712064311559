import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import {
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Button } from "@shopify/polaris";

import {
  setShowErrorModal,
  setShowSuccessModal,
} from "../../store/slices/preferenceSlice";
import {
  setIsInvoiceSyncRulesSaved,
  onChangePreferenceForStages,
  onChangePreferenceForInvoice,
  onChangeDiscount,
} from "../../store/slices/invoiceSlice";

import FieldsDropdown from "../FieldsDropdown";

import { getUrl } from "../../helpers/url";

import { useCmsHook } from "../../helpers/cmsHook";

const SyncRules = () => {
  const dispatch = useDispatch();

  const { cmsInstance } = useCmsHook();

  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    attachPDFToInvoice,
    preferenceForStages,
    preferenceForInvoice,
    discount: { orderDiscount, shippingDischarge },
    orderDiscountPreference,
    shippingDischargePreference,
  } = useSelector((state) => state.invoice);

  // mapping states
  const {
    economic: {
      loading: economicLoading,
      economicDropdownFields: { products: economicProducts },
    },
  } = useSelector((state) => state.mapping);

  return (
    <div>
      {economicLoading || !cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center my-3"
          style={{ height: "40vh" }}
        >
          <Spinner
            animation="border"
            variant="secondary"
            role="status"
            size="md"
          ></Spinner>
        </div>
      ) : (
        <div className="px-3">
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.invoice.syncRules.section1.text"
              )}
            <div className="mt-3">
              <Form.Check
                key="orderDiscount"
                type="checkbox"
                id="orderDiscount"
                name="discount"
                label="Order discount"
                className=""
                checked={orderDiscount}
                onChange={(e) =>
                  dispatch(
                    onChangeDiscount({
                      selectedOption: "orderDiscount",
                    })
                  )
                }
              />
              <div
                className="my-3"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                {orderDiscount &&
                  cmsInstance &&
                  cmsInstance.showRichText(
                    "cmsContent.syncSettings.invoice.syncRules.section1.orderDiscountText"
                  )}
                {orderDiscount && (
                  <Row className="mt-3">
                    <Col lg={3} md={5}>
                      <p className="dropdown-header">Select product</p>
                      <FieldsDropdown
                        fieldsData={economicProducts}
                        dropdownFor="orderDiscount"
                        selectedPreference={orderDiscountPreference}
                      />
                    </Col>
                  </Row>
                )}
              </div>
              <Form.Check
                key="shippingDischarge"
                type="checkbox"
                id="shippingDischarge"
                name="discount"
                label="Shipping charges"
                className=""
                checked={shippingDischarge}
                onChange={(e) =>
                  dispatch(
                    onChangeDiscount({
                      selectedOption: "shippingDischarge",
                    })
                  )
                }
              />
              <div
                className="my-3"
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                {shippingDischarge &&
                  cmsInstance &&
                  cmsInstance.showRichText(
                    "cmsContent.syncSettings.invoice.syncRules.section1.shippingDisChargeText"
                  )}
                {shippingDischarge && (
                  <Row className="mt-3">
                    <Col lg={3} md={5}>
                      <p className="dropdown-header">Select product</p>
                      <FieldsDropdown
                        fieldsData={economicProducts}
                        dropdownFor="shippingDischarge"
                        selectedPreference={shippingDischargePreference}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.invoice.syncRules.section2"
              )}

            <div className="mt-3 px-1 d-flex w-50">
              <Form.Check
                type="radio"
                id="fulfillmentStatus"
                name="stages"
                value="fulfillment stage"
                label="Fulfilled"
                className="radio-button me-5"
                checked={preferenceForStages === "fulfillment stage"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForStages({
                      selected: e.target.value,
                    })
                  )
                }
              />
              <Form.Check
                type="radio"
                id="paymentStatus"
                name="stages"
                value="payment stage"
                label="Paid"
                className="radio-button"
                checked={preferenceForStages === "payment stage"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForStages({
                      selected: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>
          <div className="sync-settings-card">
            {cmsInstance &&
              cmsInstance.showRichText(
                "cmsContent.syncSettings.invoice.syncRules.section3"
              )}
            <div className="pdf-text-card mt-3 d-flex">
              <Form.Check
                type="radio"
                id="draftInvoice"
                name="invoice"
                value="draft invoice"
                label="Draft invoice"
                className="radio-button me-5"
                checked={preferenceForInvoice === "draft invoice"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForInvoice({ selected: e.target.value })
                  )
                }
              />
              <Form.Check
                type="radio"
                id="paidInvoice"
                name="invoice"
                value="paid invoice"
                label="Bookmarked invoice"
                className="radio-button"
                checked={preferenceForInvoice === "paid invoice"}
                onChange={(e) =>
                  dispatch(
                    onChangePreferenceForInvoice({ selected: e.target.value })
                  )
                }
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end mt-4 mb-2">
            {(orderDiscount && !orderDiscountPreference) ||
            (shippingDischarge && !shippingDischargePreference) ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    {showTooltipText(
                      orderDiscount,
                      orderDiscountPreference,
                      shippingDischarge,
                      shippingDischargePreference
                    )}
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    className="sync-settings-button"
                    style={{ cursor: "not-allowed" }}
                    disabled
                  >
                    Save changes
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                className="sync-settings-button"
                disabled={isLoading}
                primary
                loading={isLoading}
                onClick={() =>
                  onSaveInvoiceSyncRules({
                    setIsLoading,
                    searchParams,
                    attachPDFToInvoice,
                    dispatch,
                    cmsInstance,
                    preferenceForStages,
                    preferenceForInvoice,
                    orderDiscount,
                    shippingDischarge,
                    orderDiscountPreference,
                    shippingDischargePreference,
                  })
                }
              >
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncRules;

export const checkCountForProductMapped = ({ mappings }) => {
  let count = 0;
  mappings.forEach((mappedItem) => {
    if (mappedItem.SH_Fields && mappedItem.SH_Fields.length > 0) {
      count += 1;
    }
  });
  return count;
};

export const onSaveInvoiceSyncRules = async ({
  setIsLoading,
  searchParams,
  dispatch,
  cmsInstance,
  preferenceForStages,
  preferenceForInvoice,
  orderDiscount,
  shippingDischarge,
  orderDiscountPreference,
  shippingDischargePreference,
}) => {
  try {
    setIsLoading(true);
    const shop = searchParams.get("shop");
    await axios.post(getUrl("REACT_APP_SAVE_PREFERENCES"), {
      shop,
      preferences: {
        deal: {
          associateInvoiceType: {
            association:
              preferenceForInvoice === "draft invoice" ? false : true,
          },
          extraData: {
            orderDiscount: orderDiscount,
            shippingDischarge: shippingDischarge,
            orderDiscountProduct: { productNumber: orderDiscountPreference },
            shippingDischargeProduct: {
              productNumber: shippingDischargePreference,
            },
          },
          triggerSettings: [
            preferenceForStages === "fulfillment stage"
              ? {
                  fulfillmentStatus: {
                    name: "Fulfilled",
                    value: "fulfilled",
                  },
                }
              : { financialStatus: { name: "Paid", value: "paid" } },
          ],
        },
      },
      preferenceType: "deal",
    });
    dispatch(setIsInvoiceSyncRulesSaved());

    dispatch(
      setShowSuccessModal({
        message:
          cmsInstance &&
          cmsInstance.sendData().cmsContent.notifications.syncSettings
            .successContent.invoiceSyncRules.message,
      })
    );
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    // toast.error(errorMessage, { className: "react-toast-error" });
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};

const showTooltipText = (
  orderDiscount,
  orderDiscountPreference,
  shippingDischarge,
  shippingDischargePreference
) => {
  if (orderDiscount && !orderDiscountPreference) {
    return "Please select the product for the order discount";
  } else if (shippingDischarge && !shippingDischargePreference) {
    return "Please select the product for the shipping discharge";
  }
};
